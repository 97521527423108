import React, { useState, useEffect, memo, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { Tooltip } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import LocalHospitalOutlined from "@material-ui/icons/LocalHospitalOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import { Checkbox, Select, InputNumber } from "antd";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown as Dropdownant } from "react-bootstrap";
import doctorLogo from "../../assets/images/fontisto_doctor.png";
import BillingFacilityIcon from "../../assets/images/BillingFacilityIcon.png";
import {
  Spinner,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import "./Popups.css";
import moment from "moment";
import axiosInstance from "../../axios/axios";
import axios from "axios";
import CustomSuffixIcon from "../popups/CustomSuffixIcon";
import { DatePicker, Radio } from "antd";
import { IoFlagOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Image, SpinnerDiv } from "../../StyledComponents";
import { Div, Span } from "../billing/CardTransactions/styles";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BsExclamationOctagon } from "react-icons/bs";
import UpgradeRequired from "../popups/UpgradeRequired";
import { repeatArray } from "../utils/RepeatFunctions/Schedule";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const NewAppointment = (props) => {
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  const { Option } = Select;
  var start = "";
  var end = "";
  const classes = useStyles();
  var currentDate = new Date();
  props.start ? (start = props.start.start) : (start = currentDate);
  props.start
    ? (end = props.start.end)
    : (end = new Date(currentDate.getTime() + 30 * 60000).toString());

  const [swict_check, setswict_check] = useState(false);
  const [swict_check_mnth, setswict_check_mnth] = useState(false);
  const [Week_and_every, setWeek_and_every] = useState(false);
  const [Month_and_every, setMonth_and_every] = useState(false);
  const [Month_and_every_Interval, setMonth_and_every_Interval] = useState("");
  const [Week_Day_Month_Year, setWeek_Day_Month_Year] = useState("");
  const [source, setSource] = useState([]);
  const [patientid, setpatientid] = useState("");
  const [search1, setsearch1] = useState("");
  const [sourceroom, setSourceRoom] = useState([]);
  const [roomval, setRoomVal] = useState("");
  const [sourceprofile, setSourceprofile] = useState([]);
  const [billingprofile, setBillingProfile] = useState([]);
  const [profileid, setprofileid] = useState("");
  const [billingid, setbillingid] = useState("");
  const [sourceprovider, setSourceprovider] = useState([]);
  const [providerid, setproviderid] = useState("");
  const [sourceoffice, setSourceoffice] = useState([]);
  const [officeid, setofficeid] = useState("");
  const [sourcestatus, setSourcestatus] = useState([]);
  const [sourcestatustele, setSourcestatustele] = useState([]);
  const [statusid, setstatusid] = useState("");
  const dtFormat = "MM-DD-YYYY";
  const [aptdate, setaptdate] = useState("");
  const [dayswise, setdayswise] = useState(false);
  const [apttime, setapttime] = useState("");
  const [aptfromdate, setappfromdate] = useState("");
  const [apttodate, setapptodate] = useState("");
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [checking1, setchecking1] = useState(false);
  const [telehealth, settelehealth] = useState([]);
  const [teleprofile, setteleprofile] = useState([]);
  const [Appttype, setAppttype] = useState(1);
  const [forteleornor, setforteleornor] = useState(1);
  const [fornoresults, setfornoresults] = useState("1");
  const [videochck, setvideochck] = useState(false);
  const [patsrchvalue, setpatsrchvalue] = useState("");
  const [normalappointment, setnormalappointment] = useState(
    localStorage.getItem("normalapppopup") ? true : false
  );
  const [teleappointment, setteleappointment] = useState(false);
  const [weekday, setweekday] = useState("");
  const [repeatselectnew, setrepeatselectnew] = useState("");
  const [repeatselect2new, setrepeatselect2new] = useState("0");
  const [dateselect2new, setdateselect2new] = useState("");
  const [timelimiterrorbox, settimelimiterrorbox] = useState(false);
  const [timelimiterror, settimelimiterror] = useState("none");
  const [patError, setpatError] = useState(false);
  const [profileError, setprofileError] = useState(false);
  const [examroomError, setexamroomError] = useState(false);
  const [billingError, setBillingError] = useState(false);
  const [provError, setprovError] = useState(false);
  const [officeError, setofficeError] = useState(false);
  const [statusError, setstatusError] = useState(false);
  const [untildateError, setuntildateError] = useState(false);
  const [everydayError, seteverydayError] = useState(false);
  const [selectdayError, setselectdayError] = useState(false);
  const [notDisabledSpec, setNotDisabledSpec] = useState("");
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [ModalMsg1, setModalMsg1] = useState("");
  const [walkincheck, setwalkincheck] = useState(false);
  const [patFlag, setPatFlag] = useState([]);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  var accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  var trial = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");
  const bill_facility_id = useSelector(
    (state) => state.Schedule.BillingFacility
  );
  var teleFlag = Decrypt_Value(localStorage.getItem("telehealth_flag"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  var startformat = moment(start, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var endformat = moment(end, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  var duration = moment(endformat, "YYYY-MM-DD HH:mm:ss").diff(
    moment(startformat, "YYYY-MM-DD HH:mm:ss"),
    "minutes"
  );
  const [billFacId, setBillFacId] = useState("");

  const [editing, setEditing] = useState(duration);
  const [btnDis, setBtnDis] = useState(false);
  const [workDays, setworkDays] = useState(0);
  const [startTime, setStartTime] = useState(9);
  const [endTime, setEndTime] = useState(17);
  const initialRender = useRef(true);
  const [showrepApp, setShowrepApp] = useState(false);
  const [repvalue, setrepValue] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [dyOfMont, setDyOfMont] = useState("");
  const [wkOfMont, setWkOfMont] = useState("");
  const [aftDate, setAftDate] = useState("");
  const [onDate, setOnDate] = useState("");
  const [onDatefetch, setOnDateFetch] = useState("");
  const [occurence, setOccurence] = useState("1");
  const [custChk, setcustChk] = useState("");
  const [tempOpen, setTempOpen] = useState(false);
  const [inTimeErr, setInTimeErr] = useState(false);
  const [wkErr, setWkErr] = useState(false);
  const [repeatselectmonth, setRepeatSelectMonth] = useState("1");
  const [custchkerr, setcustchkerr] = useState(false);
  const [endErrTime, setEndErrTime] = useState("");
  const [startErrTime, setStartErrTime] = useState("");
  const [frtimeSlots, setFromTimeSlots] = useState([]);
  const [totimeSlots, setToTimeSlots] = useState([]);
  const [upgrade, setUpgrade] = useState(false);
  const [userAvail, setUserAvail] = useState([]);
  const [Telehealthplatform, setTelehealthplatform] = useState("zoom");

  let GMeetURL = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  const [speciality, setSpeciality] = useState("");
  const [specialError, setSpecialError] = useState(false);
  const [allowAppt, setAllowAppt] = useState(0);
  const [occurenceday, setOccurenceday] = useState();
  const [occurencedayErr, setOccurencedayErr] = useState(false);
  const [lastdate, setLastdate] = useState(false);
  const [lastdateApp, setLastdateApp] = useState("");
  const [sortSpeciality, setSortSpecility] = useState("");
  const specialityList = [
    "Plastic Surgery",
    "Dermatology",
    "Sleep Medicine",
    "Allergy & Immunology",
    "Concierge Medicine",
    "Oncology",
    "Palliative Care",
    "Endocrinology",
    "Sports Medicine",
    "Hospice",
    "Podiatry",
    "Pediatrics",
    "Pathology",
    "Obstetrics and Gynecology",
    "Hand Surgery",
    "Hematology",
    "Emergency Medicine",
    "Surgery",
    "Cardiology",
    "Vascular Surgery",
    "Pulmonology",
    "Austism",
    "Radiology",
    "ECG Management",
    "Physical Medicine & Rehabilitation",
    "Opthalmology",
    "Geriatric Medicine",
    "Neurology",
    "Infectious Disease",
    "Nephrology",
    "Ambulatory Care & Surgery",
    "Otolaryngology (ENT)",
    "Rheumatology",
    "Dental Health",
    "Chiropractic",
    "Psychiatry",
    "Urology",
    "Wound Care",
    "Orthopedic Surgery",
    "Behaviour Health",
    "Gastroenterology",
    "Anesthesiology",
    "Family Medicine",
    "Behavioral health therapy",
    "Acupuncture",
    "Lactation consulting",
    "Massage therapy",
    "Counseling",
    "Marriage and family therapy",
    "Psychology",
    "Social work",
    "Dietetics or nutrition counseling",
    "Applied behavior analysis",
    "Occupational therapy",
    "Physical therapy",
    "Speech-language pathology",
    "Substance use counseling",
    "PCP",
    "Other",
  ];
  const [billFacility, setBillFacility] = useState([]);
  const [billFacilityID, setBillFacilityID] = useState("");
  const [billFacilityError, setBillFacilityError] = useState(false);

  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //
  const handleSaverepApp = () => {
    if (repeatselect2new === "1") {
      setWeek_Day_Month_Year("week");
    } else if (repeatselect2new === "2") {
      setWeek_Day_Month_Year("month");
    } else if (repeatselect2new === "0") {
      setWeek_Day_Month_Year("day");
    }
    if (repeatselectnew === "") {
      seteverydayError(true);
    } else if (
      repeatselect2new === "1" &&
      (weekday === "" || weekday.length == 1)
    ) {
      setselectdayError(true);
    } else if (custChk === "") {
      setcustchkerr(true);
    } else {
      setselectdayError(false);
      setcustchkerr(false);
      setShowrepApp(false);
    }
    if (occurencedayErr) {
      setShowrepApp(true);
      return false;
    }
    setstatusid("");
  };

  const handleCloserepApp = () => {
    setShowrepApp(false);
    setrepValue("Does not repeat");
    setdateselect2new("");
    setcustChk("");
    setweekday("");
    setswict_check(false);
    setswict_check_mnth(false);
    setWeek_Day_Month_Year("");
    setrepeatselectnew("");
    setrepeatselect2new("");
    setOnDateFetch("");
    setOnDate("");
    setcustChk("");
    setOccurencedayErr(false);
    setOccurenceday();
    setOccurence("1");
  };

  const handleShowrepApp = () => {
    setShowrepApp(true);
    setLastdate(false);
    setchecking1(true);

    if (Week_Day_Month_Year === "day") {
      setswict_check(false);
      setswict_check_mnth(false);
    } else if (Week_Day_Month_Year === "week") {
      setswict_check(true);
      setswict_check_mnth(false);
    } else if (Week_Day_Month_Year === "month") {
      setswict_check(false);
      setswict_check_mnth(true);
    } else {
      setrepeatselectnew("1");
      setrepeatselect2new("1");
      setswict_check(true);
      setweekday("");
      setRepeatSelectMonth("1");
      setWeek_and_every(true);
    }
  };
  const handleShowuntildate = () => {
    setLastdate(true);
  };
  const handleShowuntildate1 = () => {
    setLastdate(false);
  };
  const setmonthDropdown = (e) => {
    if (e === "2") {
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
      if (wkOfMont == "First") {
        setMonth_and_every_Interval("1");
      } else if (wkOfMont == "Second") {
        setMonth_and_every_Interval("2");
      } else if (wkOfMont == "Third") {
        setMonth_and_every_Interval("3");
      } else if (wkOfMont == "Fourth") {
        setMonth_and_every_Interval("4");
      } else {
        setMonth_and_every_Interval("5");
      }

      setMonth_and_every(true);
    } else if (e === "1") {
      setrepeatselect2new("2");
      setweekday("");
      setMonth_and_every(false);
    }
    setRepeatSelectMonth(e);
  };

  const handlerepSelect = (e) => {
    if (e === "Does not repeat") {
      setchecking1(false);
      setdayswise(false);
      setMonth_and_every(false);
      setrepeatselectnew("");
      setrepeatselect2new("");
      setRepeatSelectMonth("1");
      setdateselect2new("");
      setweekday("");
      setcustChk("");
    } else if (e === "Daily") {
      setstatusid("");
      setchecking1(true);
      setrepeatselectnew("1");
      setrepeatselect2new("0");
      setdayswise(false);
      setMonth_and_every(false);
    } else if (e.includes("Weekly on")) {
      setstatusid("");
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
    } else if (e.includes("Monthly on the")) {
      setstatusid("");
      setchecking1(true);
      if (day === "Monday") {
        setweekday(["", 2]);
      } else if (day === "Tuesday") {
        setweekday(["", 3]);
      } else if (day === "Wednesday") {
        setweekday(["", 4]);
      } else if (day === "Thursday") {
        setweekday(["", 5]);
      } else if (day === "Friday") {
        setweekday(["", 6]);
      } else if (day === "Saturday") {
        setweekday(["", 7]);
      } else if (day === "Sunday") {
        setweekday(["", 1]);
      }
      if (wkOfMont == "First") {
        setMonth_and_every_Interval("1");
      } else if (wkOfMont == "Second") {
        setMonth_and_every_Interval("2");
      } else if (wkOfMont == "Third") {
        setMonth_and_every_Interval("3");
      } else if (wkOfMont == "Fourth") {
        setMonth_and_every_Interval("4");
      } else {
        setMonth_and_every_Interval("5");
      }
      setrepeatselectnew("1");
      setrepeatselect2new("2");
      setMonth_and_every(true);
      setdayswise(false);
    } else if (e.includes("Every Weekday")) {
      setstatusid("");
      setchecking1(true);
      setdayswise(true);
      setMonth_and_every(false);
      setweekday(["", 2, 3, 4, 5, 6]);
    }
    setrepValue(e);
  };

  const handlerepDate = (e) => {
    if (e) {
      setOnDate(moment(e).format(dtFormat));
    } else {
      setOnDate(moment().format(dtFormat));
    }
    if (e !== null) {
      setOnDateFetch(e._d);
    }

    setdateselect2new("On");
    setcustChk(2);
  };

  const convertHMS = (value) => {
    return Math.floor(value * 60);
  };

  const disabledFromTimes = (time, meridiem, type) => {
    const now = moment();
    const currentTime = moment(`${time} ${meridiem}`, "h:mm A");
    const appointmentDate = moment(aptdate);
    if (appointmentDate.isSame(now, "day")) {
      if (type === 2 && currentTime.isBefore(now)) {
        return true;
      }
    }
    return false;
  };
  const disabledToTime = (time, meridiem, id, type) => {
    if (!inTime) return false;

    const fromTime = moment(inTime, "h:mm A");
    const toTime = moment(`${time} ${meridiem}`, "h:mm A");
    if (toTime.format("h:mm A") === "12:00 AM") {
      return false;
    }
    const appointmentDate = moment(aptdate);
    if (fromTime.isAfter(toTime) || fromTime.isSame(toTime)) {
      return true;
    }

    return false;
  };

  function reformatDate(inputDate, ddFormat) {
    const formatParts = ddFormat.split(/[-/]/);
    const dateParts = inputDate.split(/[-/]/);

    let dayIndex, monthIndex, yearIndex;
    let day, month, year;

    if (formatParts.includes("DD")) {
      dayIndex = formatParts.indexOf("DD");
    } else {
      throw new Error("Date format must include 'DD'");
    }

    if (formatParts.includes("MM")) {
      monthIndex = formatParts.indexOf("MM");
    } else {
      throw new Error("Date format must include 'MM'");
    }

    if (formatParts.includes("YYYY") || formatParts.includes("YY")) {
      yearIndex =
        formatParts.indexOf("YYYY") !== -1
          ? formatParts.indexOf("YYYY")
          : formatParts.indexOf("YY");
    } else {
      throw new Error("Date format must include 'YYYY' or 'YY'");
    }

    // Extract day, month, and year based on indices
    day = parseInt(dateParts[dayIndex], 10);
    month = parseInt(dateParts[monthIndex], 10) - 1; // Month is zero-based in JavaScript
    year = parseInt(dateParts[yearIndex], 10);

    const date = new Date(Date.UTC(year, month, day));

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    return date.toISOString().split("T")[0];
  }

  function getNthWeekdayOfMonth(year, month, weekday, n) {
    // Get the first day of the month
    let date = new Date(year, month, 1);

    // Adjust weekday index if Sunday is represented as 1
    let dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    let diff = (weekday - dayOfWeek + 7) % 7;
    date.setDate(1 + diff);

    // Move to the nth occurrence
    date.setDate(date.getDate() + (n - 1) * 7);

    return date;
  }

  const buttonClickActions = async (e) => {
    let MonthOccurences;

    e.preventDefault();
    if (billFacId === undefined) {
      props.redirect();
      return;
    }
    var statusid_temp;
    if (checking1 === true) {
      statusid_temp = "";
    } else {
      statusid_temp = statusid;
    }

    if (inTime === "") {
      setInTimeErr(true);
      return;
    }
    if (billFacilityID === "" || billFacilityID === null) {
      setBillFacilityError(true);
      return;
    }
    let availcheckfilt = userAvail.filter((x) => {
      return x.pc_eventDate === aptdate;
    });

    if (availcheckfilt.length !== 0) {
      for (let appointment of availcheckfilt) {
        let intimeString = appointment.pc_eventDate + " " + inTime;
        let outtimeString = appointment.pc_eventDate + " " + outTime;

        let intimeDate = new Date(intimeString);
        let outtimeDate = new Date(outtimeString);
        let startTimeString =
          appointment.pc_eventDate + " " + appointment.pc_startTime;
        let endTimeString =
          appointment.pc_eventDate + " " + appointment.pc_endTime;
        let startTimeDate = new Date(startTimeString);
        let endTimeDate = new Date(endTimeString);

        // Check if outtime is the same as pc_startTime
        if (outtimeDate.getTime() === startTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check if intime is the same as pc_endTime
        if (intimeDate.getTime() === endTimeDate.getTime()) {
          // Skip the if condition and continue to the next appointment
          continue;
        }

        // Check for overlap only if the conditions above are not met

        if (
          (startTimeDate <= outtimeDate && endTimeDate >= intimeDate) ||
          (endTimeDate >= intimeDate && startTimeDate <= outtimeDate) ||
          (startTimeDate <= intimeDate && endTimeDate >= outtimeDate)
        ) {
          setInTimeErr(true);
          return;
          // You might add a break statement here if you want to exit the loop after finding an overlap
        } else {
          setInTimeErr(false);
        }
      }
    }

    // if (inTime !== "") {
    //   let y = checkTimeAvailablity(inTime, Appttype, "from");
    //   if (y) {

    //     setInTimeErr(true);
    //     return;
    //   }
    // }

    // if (outTime !== "") {
    //   let y = checkTimeAvailablity(outTime, Appttype, "to");

    //   if (y) {

    //     setInTimeErr(true);
    //     return;
    //   }
    // }

    const timenew_alert = moment(outTime, ["h:mm A"]).format("HH:mm");
    const time = timenew_alert.split(":");
    const timeHour = time[0];
    // const a = moment(tempdate).add(15, "minutes").format("h:mma");
    const a = moment(timenew_alert, "hh:mm A").format("hh:mm A");

    const maintime = moment(a, "h:mma");

    var b;
    if (endTime !== "12") {
      b = moment(`0${endTime - 12}:00pm`, "h:mma");
    } else {
      b = moment(`${endTime - 12}:00pm`, "h:mma");
    }

    if (inTimeErr || timelimiterrorbox) {
      return false;
    }
    if (
      Number(startTime) <= Number(timeHour) &&
      Number(timeHour) <= Number(endTime)
    ) {
      settimelimiterrorbox(false);
      settimelimiterror("none");
    } else {
      settimelimiterrorbox(true);
      settimelimiterror("block");
      return false;
    }

    let splitTime = apttime.split(":");
    let splitMin = splitTime[1].split(" ");

    if (splitMin[1] === "PM" && Number(splitTime[0]) !== Number(12)) {
      let totTime = +splitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      } else if (totTime < startTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    let outSplitTime = outTime.split(":");
    let outSplitMin = outSplitTime[1].split("");

    if (outSplitMin[1] === "PM" && Number(outSplitTime[0]) !== Number(12)) {
      let totTime = +outSplitTime[0] + +12;
      if (totTime >= outTime) {
        settimelimiterrorbox(true);
        return false;
      }
    }

    var timenew = aptdate + " " + apttime;
    var dtFormatWithTime1 = `${dtFormat} hh:mm a`;

    var timedetnew = moment(timenew, dtFormatWithTime1).format(
      "YYYY-MM-DD HH:mm a"
    );
    var durationnew = editing;
    var appttypenew = Appttype;
    // var alldaynew = alldaydisable;
    var walkinnew = walkincheck;
    var select1, select2, untildate, fromdate, todate, days_wise, repeats;
    if (weekday != null) {
      if (weekday || swict_check) {
        if (swict_check && (weekday === "" || weekday.length == 1)) {
          setWkErr(true);
          return false;
        } else {
          setWkErr(false);
          repeats = weekday;
        }
      } else {
        repeats = "";
      }
    } else {
      repeats = "";
    }

    if (repeatselectnew) {
      select1 = repeatselectnew;
    } else {
      select1 = 0;
    }

    if (repeatselect2new) {
      select2 = repeatselect2new;
    } else {
      select2 = "";
    }

    var repeat_type;
    var recurranceCount;

    if (dateselect2new) {
      if (dateselect2new === "Never") {
        repeat_type = "Never";
        recurranceCount = "";
      } else if (dateselect2new === "On") {
        untildate =
          onDate === ""
            ? moment(aftDate, dtFormat).format("YYYY-MM-DD")
            : moment(onDate, dtFormat).format("YYYY-MM-DD");
        repeat_type = "On";
        recurranceCount = "";
      } else if (dateselect2new === "After") {
        if (select2 === 0 || select2 === "0") {
          function calculateDailyOccurrences(
            startDate,
            totalOccurrences,
            dayGap
          ) {
            let occurrences = [];
            let currentDate = new Date(startDate);

            // Format date as 'YYYY-MM-DD'
            function formatDate(date) {
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              return `${year}-${month}-${day}`;
            }

            // Generate occurrences
            for (let i = 0; i < totalOccurrences; i++) {
              occurrences.push(formatDate(currentDate));
              currentDate.setDate(currentDate.getDate() + parseInt(dayGap)); // Move to the next occurrence
            }

            return occurrences;
          }

          let occurrences = calculateDailyOccurrences(
            reformatDate(aptdate, dtFormat),
            occurence,
            repeatselectnew
          );
          untildate = occurrences[occurrences.length - 1];
          recurranceCount = occurence;
        } else if (select2 === "2" || select2 === 2) {
          if (repeatselectmonth === 1 || repeatselectmonth === "1") {
            function calculateMonthlyOccurrences(
              startDate,
              totalOccurrences,
              intervalMonths
            ) {
              let occurrences = [];
              let currentDate = new Date(startDate);

              // Format date as 'YYYY-MM-DD'
              function formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                return `${year}-${month}-${day}`;
              }

              // Generate occurrences
              for (let i = 0; i < totalOccurrences; i++) {
                occurrences.push(formatDate(currentDate));

                // Increment month
                currentDate.setMonth(
                  currentDate.getMonth() + parseInt(intervalMonths)
                );

                // Handle cases where day might overflow the end of the month
                if (
                  currentDate.getDate() >
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    0
                  ).getDate()
                ) {
                  // Adjust to the last day of the month if overflow occurs
                  currentDate.setDate(0);
                }
              }

              return occurrences;
            }
            let occurrences = calculateMonthlyOccurrences(
              aptdate,
              occurence,
              repeatselectnew
            );

            // Determine the last occurrence date
            untildate = occurrences[occurrences.length - 1];
            recurranceCount = occurence;
            MonthOccurences = occurrences;
            // console.log("Occurrences:", occurrences);
            // console.log("Until Date:", untildate);
          } else if (repeatselectmonth === 2 || repeatselectmonth === "2") {
            function calculateMonthlyOccurrencesForWeekday(
              startDate,
              totalOccurrences,
              wkOfMont,
              day
            ) {
              let occurrences = [];
              let currentDate = new Date(startDate);

              // Map weekday names to day indices (Sunday as 1)
              const weekdayMap = {
                Sunday: 1,
                Monday: 2,
                Tuesday: 3,
                Wednesday: 4,
                Thursday: 5,
                Friday: 6,
                Saturday: 7,
              };

              // Convert wkOfMont to a number (e.g., "second" to 2)
              const weekMap = {
                first: 1,
                second: 2,
                third: 3,
                fourth: 4,
                last: 5,
              };

              let weekNumber = weekMap[wkOfMont.toLowerCase()];
              let weekdayIndex = weekdayMap[day];

              for (let i = 0; i < totalOccurrences; i++) {
                let year = currentDate.getFullYear();
                let month = currentDate.getMonth();

                // Calculate the nth weekday of the month
                let occurrenceDate = getNthWeekdayOfMonth(
                  year,
                  month,
                  weekdayIndex - 1,
                  weekNumber
                );

                // Format the date as 'YYYY-MM-DD'
                function formatDate(date) {
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, "0");
                  const day = String(date.getDate()).padStart(2, "0");
                  return `${year}-${month}-${day}`;
                }

                occurrences.push(formatDate(occurrenceDate));

                // Move to the next month
                currentDate.setMonth(currentDate.getMonth() + 1);
              }

              return occurrences;
            }

            let occurrences = calculateMonthlyOccurrencesForWeekday(
              aptdate,
              occurence,
              wkOfMont,
              day
            );

            // Determine the last occurrence date
            untildate = occurrences[occurrences.length - 1];
            recurranceCount = occurence;
            MonthOccurences = occurrences;
            // console.log("Occurrences:", occurrences);
            // console.log("Until Date:", untildate);
          }
        } else if (select2 === 1 || select2 === "1") {
          const filteredData = weekday.filter((item) => item !== "");
          const sortedData = filteredData
            .map((day) => parseInt(day))
            .sort((a, b) => a - b);

          function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
          }

          function calculateOccurrences(
            startDate,
            weekdays,
            totalOccurrences,
            weeksBetween
          ) {
            // console.log(
            //   "=========",
            //   startDate,
            //   weekdays,
            //   totalOccurrences,
            //   weeksBetween,
            //   "================"
            // );

            let occurrences = [];
            let currentDate = new Date(startDate);
            let occurrencesCount = 0;

            // Adjust weeksBetween to a number
            weeksBetween = Number(weeksBetween);

            // Adjusting days of the week to match the requirement: Sunday = 1, Monday = 2, ..., Saturday = 7
            function getDayOfWeek(day) {
              return (day % 7) - 1; // Convert 1-based to 0-based for JavaScript's getDay
            }

            // Helper function to get the next specific weekday from a given date
            function getNextWeekday(date, dayOfWeek) {
              let d = new Date(date);
              let currentDay = (d.getDay() + 1) % 7; // Adjust to 1-based (Sunday = 1, Monday = 2, ..., Saturday = 7)
              let daysUntilNext = (dayOfWeek - currentDay + 7) % 7; // Calculate days until the next occurrence

              if (daysUntilNext === 0) daysUntilNext = 7; // Ensure it's in the future
              d.setDate(d.getDate() + daysUntilNext);
              return d;
            }

            // Determine if the start date itself is one of the weekdays
            function isStartDateWeekday(startDate, weekdays) {
              let dayOfWeek = (startDate.getDay() + 1) % 7; // Adjust to 1-based
              return weekdays.includes(dayOfWeek);
            }

            // Find the closest weekday to the start date
            function findClosestWeekday(startDate, weekdays) {
              let minDaysDiff = Infinity;
              let closestDate = null;

              weekdays.forEach((day) => {
                let weekdayDate = getNextWeekday(startDate, day);
                let daysDiff = Math.abs(
                  (weekdayDate - startDate) / (1000 * 60 * 60 * 24)
                );
                if (daysDiff < minDaysDiff) {
                  minDaysDiff = daysDiff;
                  closestDate = weekdayDate;
                }
              });

              return closestDate;
            }

            // Find the first occurrence
            let firstOccurrenceDate = isStartDateWeekday(currentDate, weekdays)
              ? currentDate
              : findClosestWeekday(currentDate, weekdays);

            occurrences.push(formatDate(firstOccurrenceDate));
            occurrencesCount++;

            // Calculate the remaining occurrences
            currentDate = new Date(firstOccurrenceDate);

            while (occurrencesCount < totalOccurrences) {
              // Calculate occurrences for the remaining weekdays in the current week
              let weekOccurrences = weekdays
                .map((day) => getNextWeekday(currentDate, day))
                .filter((date) => date >= currentDate); // Only include dates that are not in the past
              // console.log(weekOccurrences, "weekOccurrences");
              weekOccurrences.sort((a, b) => a - b);

              // Add the occurrences of the current week if needed
              for (let date of weekOccurrences) {
                if (occurrencesCount >= totalOccurrences) break;
                occurrences.push(formatDate(date));
                occurrencesCount++;

                // if(select1==2){
                //   currentDate.setDate(currentDate.getDate() + weeksBetween * 7);

                // }
              }

              // Move to the next set of weeks based on weeksBetween if more occurrences are needed
              if (occurrencesCount < totalOccurrences) {
                currentDate.setDate(currentDate.getDate() + weeksBetween * 7);
              }
            }

            occurrences.sort((a, b) => new Date(a) - new Date(b));

            return occurrences;
          }
          // Calculate the occurrences
          let occurrences = calculateOccurrences(
            reformatDate(aptdate, dtFormat),
            sortedData,
            occurence,
            repeatselectnew
          );
          // console.log(occurrences, "occurrences");
          untildate = occurrences[occurrences.length - 1];
          // console.log(untildate, "untildate");
          repeat_type = "After";
          recurranceCount = occurence;
        }
      }
    }
    // else if (dateselect2new && swict_drop === true) {
    //   if (dateselect2new === "Never") {
    //     repeat_type = "Never";
    //   } else if (dateselect2new === "On") {
    //     untildate = onDate;
    //     repeat_type = "On";
    //   } else if (dateselect2new === "After") {
    //   }
    // }
    else {
      if (checking1) {
        untildate = "";
        repeat_type = "notCustom";
      } else {
        untildate = "";
        repeat_type = "norepeat";
      }
    }

    if (aptfromdate) {
      fromdate = aptfromdate;
    } else {
      fromdate = "";
    }

    if (apttodate) {
      todate = apttodate;
    } else {
      todate = "";
    }

    if (dayswise != null) {
      if (dayswise) {
        days_wise = true;
      } else {
        days_wise = false;
      }
    } else {
      days_wise = false;
    }

    // var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();

    // var startTimenew = moment(tempdatenew).format("hh:mm a");

    // var returned_endatenew = moment(tempdatenew, "YYYY-MM-DD hh:mm a")
    //   .add(durationnew, "minutes")
    //   .format("YYYY-MM-DD hh:mm a");

    // var startdatenew = moment(tempdatenew).format("YYYY-MM-DD");
    // var secondsnew = convertHMS(durationnew);
    // var endTimenew = moment(outTime, "h:mm A").format("hh:mm a");
    // var endDatenew = moment(returned_endatenew, "YYYY-MM-DD HH:mm A").format(
    //   "YYYY-MM-DD"
    // );

    var tempdatenew = moment(timedetnew, moment.defaultFormat).toDate();
    var startTimenew = moment(tempdatenew).format("hh:mm a");
    var returned_endatenew = moment(tempdatenew, dtFormatWithTime1)
      .add(durationnew, "minutes")
      .format("YYYY-MM-DD hh:mm a");
    var startdatenew = moment(tempdatenew, dtFormat).format("YYYY-MM-DD");
    var secondsnew = convertHMS(durationnew);
    var endTimenew = moment(outTime, "h:mm A").format("hh:mm a");
    var endDatenew = moment(returned_endatenew, "YYYY-MM-DD hh:mm a").format(
      "YYYY-MM-DD"
    );
    var MeetMode = Telehealthplatform;

    const repeatsString =
      repeats &&
      repeats
        .filter((value) => {
          if (typeof value === "number") {
            return !isNaN(value);
          }
          if (typeof value === "string") {
            const num = Number(value.trim());
            return !isNaN(num) && num !== "";
          }
          return false;
        })
        .map((value) =>
          typeof value === "string" ? Number(value.trim()) : value
        )
        .filter((num) => num !== 0)
        .sort((a, b) => a - b)
        .join(",");
    var article = {
      pc_pid: patientid,
      pc_catid: profileid,
      pc_billid: billingid !== "" ? Number(billingid) : null,
      pc_aid: providerid,
      pc_title: statusid_temp,
      pc_duration: secondsnew,
      pc_hometext: "",
      pc_apptstatus: statusid_temp,
      pc_eventDate: startdatenew,
      pc_endDate: endDatenew,
      pc_startTime: startTimenew,
      pc_endTime: endTimenew,
      pc_facility: officeid,
      pc_informant: providerid,
      pc_billing_location: "10",
      pc_select1: select1,
      pc_select2: select2,
      pc_untildate:
        repvalue === "Custom" && untildate !== ""
          ? moment(untildate).format("YYYY-MM-DD")
          : moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),

      pc_lastdate: moment(lastdateApp, dtFormat).format("YYYY-MM-DD"),
      pc_fromdate: fromdate,
      pc_todate: todate,
      days_wise: days_wise,
      days_of_week: repeatsString,
      // allday: alldaynew,
      repeats: repeats,
      appttype: appttypenew,
      walkin: walkinnew,
      examRoom: roomval,
      id: authorized === "1" ? userID : main_pro,
      pc_repeat_type: repeat_type,
      week_and_every_day: Week_and_every,
      month_and_every_day: Month_and_every,
      Month_and_every_Interval: Month_and_every_Interval,
      ocurrance_count: recurranceCount ? recurranceCount : "",
      on_never_after: custChk,
      repeat_select: repvalue,
      pc_repeatselectmonth: repeatselectmonth,
      selectMonthAlp:
        repeatselectmonth === 1 || repeatselectmonth === "1"
          ? `Monthly on day ${dyOfMont}`
          : `Monthly on the ${wkOfMont} ${day}`,
      repeatSelectMonthOccurenceDates: MonthOccurences,
      speciality,
      mode: MeetMode,
      billingFacilityId: billFacilityID,
    };
    // console.log(article, "article");
    let encryptedData = Encrypt_Value(article, "vozo");

    // if (checking1 === true) {
    //   var error = 0;
    //   if (days_wise === true) {
    //     if (repeats === "") {
    //       setselectdayError(true);
    //       error = 2;
    //     }
    //     if (untildate === "") {
    //       setuntildateError(true);
    //       error = 2;
    //     }
    //   } else if (days_wise === false) {
    //     if (repeatselectnew === "") {
    //       if (untildate === "") {
    //         setuntildateError(true);
    //         error = 2;
    //       }
    //       if (repeatselectnew === "") {
    //         seteverydayError(true);
    //         error = 2;
    //       }
    //     }
    //   }
    //   if (error > 0) {
    //     return false;
    //   }
    // }

    if (select1 === 0) {
      let error = 1;
      if (
        patientid === "" ||
        (Appttype !== 2 && profileid === "") ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        speciality === "" ||
        roomval === ""
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }
        if (Appttype !== 2 && profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (roomval === "") {
          setexamroomError(true);
          error = 2;
        }
        // if (billingid === "" && Appttype !== 2) {
        //   setBillingError(true);
        //   error = 2;
        // }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        // if (statusid === "") {
        //   setstatusError(true);
        //   error = 2;
        // }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }
        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }

        if (error > 1) {
          return false;
        }
      } else {
        // setSpinnerVal(true);
        setBtnDis(true);
        if (statusid === "doc_confirmed" || statusid === "EMAIL") {
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data == "pass") {
                props.setspinnerfornewapp();
                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then((res) => {
                    let apptAdded = res.data;
                    // return;
                    if (
                      MeetMode === "GMeet" &&
                      videochck &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");

                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                            fac_id: res["pc_facility"],
                          };

                          axios
                            .get(
                              GMeetURL +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {
                              axiosInstance
                                .get("/vozo/tele/checkemailsent")
                                .then((response) => {
                                  setSpinnerVal(false);
                                  props.savealertmsg(response.data, true);
                                  setTimeout(() => props.popuphide(), 2000);
                                })
                                .catch((err) => {
                                  setSpinnerVal(false);
                                  console.error(err);
                                });

                              if (apptAdded === "out of both range") {
                                setBtnDis(false);
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected Date & time should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (apptAdded === "out of time range") {
                                setBtnDis(false);
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected time should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (apptAdded === "out of date range") {
                                setBtnDis(false);
                                setModalAlerShow1(true);
                                setModalMsg1(
                                  "Selected Date should be within the provider availability range."
                                );
                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (
                                apptAdded === "Provider Not Available"
                              ) {
                                setBtnDis(false);
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1("Provider Not Available");
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else if (
                                apptAdded ===
                                "You Can't place the Appointment at this time"
                              ) {
                                setBtnDis(false);
                                setTimeout(() => {
                                  setModalAlerShow1(true);
                                  setModalMsg1(
                                    "You Can't place the Appointment at this time"
                                  );
                                }, 1);

                                setTimeout(
                                  () => setModalAlerShow1(false),
                                  2000
                                );
                                return;
                              } else {
                                props.savealertmsg(
                                  "New Appointment Added Successfully",
                                  true
                                );
                              }
                              setTimeout(() => props.popuphide(), 2000);
                              props.FetchAppointments(
                                props.provarray,
                                props.facarray,
                                props.appFromDate,
                                props.apptoDate,
                                props.pat_share,
                                props.examroom
                              );
                            })
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else {
                      if (
                        statusid === "doc_confirmed" ||
                        statusid === "EMAIL"
                      ) {
                        axiosInstance
                          .get("/vozo/tele/checkemailsent")
                          .then((response) => {
                            setSpinnerVal(false);
                            props.savealertmsg(response.data, true);
                            setTimeout(() => props.popuphide(), 2000);
                          })
                          .catch((err) => {
                            setSpinnerVal(false);
                            console.error(err);
                          });
                      }

                      if (apptAdded === "out of both range") {
                        setBtnDis(false);
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date & time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of time range") {
                        setBtnDis(false);
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected time should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "out of date range") {
                        setBtnDis(false);
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "Selected Date should be within the provider availability range."
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (apptAdded === "Provider Not Available") {
                        setBtnDis(false);
                        setModalAlerShow1(true);
                        setModalMsg1("Provider Not Available");

                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else if (
                        apptAdded ===
                        "You Can't place the Appointment at this time"
                      ) {
                        setBtnDis(false);
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "You Can't place the Appointment at this time"
                        );
                        setTimeout(() => setModalAlerShow1(false), 2000);
                        return;
                      } else {
                        props.savealertmsg(
                          "New Appointment Added Successfully",
                          true
                        );
                      }
                      setTimeout(() => props.popuphide(), 2000);
                      props.FetchAppointments(
                        props.provarray,
                        props.facarray,
                        props.appFromDate,
                        props.apptoDate,
                        props.pat_share,
                        props.examroom
                      );
                    }
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              setSpinnerVal(false);
              setBtnDis(false);
              console.error(err);
            });
        } else {
          // props.setspinnerfornewapp();

          axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then((res) => {
              let apptAdded = res.data;
              // console.log(res.data, "response for  appt");
              // return;
              if (
                MeetMode === "GMeet" &&
                videochck &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");

                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };

                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });

                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    setSpinnerVal(false);
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => {
                    setSpinnerVal(false);
                    console.error(err);
                  });
              }

              if (apptAdded === "out of both range") {
                setBtnDis(false);
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date & time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of time range") {
                setBtnDis(false);
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1(
                    "Selected time should be within the provider availability range."
                  );
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "Provider Not Available") {
                setBtnDis(false);
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("Provider Not Available");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded === "You Can't place the Appointment at this time"
              ) {
                setBtnDis(false);
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("You Can't place the Appointment at this time");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of date range") {
                setBtnDis(false);
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else {
                props.savealertmsg("New Appointment Added Successfully", true);
              }
              setTimeout(() => props.popuphide(), 2000);
              setSpinnerVal(false);

              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share,
                props.examroom
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    } else if (select1 > 0) {
      let error = 1;
      if (
        patientid === "" ||
        profileid === "" ||
        providerid === "" ||
        providerid === null ||
        providerid === undefined ||
        // statusid === "" ||
        officeid === "" ||
        speciality === "" ||
        roomval === ""
        // ||
        // untildate === ""
      ) {
        if (patientid === "") {
          setpatError(true);
          error = 2;
        }
        if (Appttype !== 2 && profileid === "") {
          setprofileError(true);
          error = 2;
        }
        if (roomval === "") {
          setexamroomError(true);
          error = 2;
        }
        // if (billingid === "" && Appttype !== 2) {
        //   setBillingError(true);
        //   error = 2;
        // }
        if (
          providerid === "" ||
          providerid === null ||
          providerid === undefined
        ) {
          setprovError(true);
          error = 2;
        }
        // if (statusid === "") {
        //   setstatusError(true);
        //   error = 2;
        // }
        if (officeid === "") {
          setofficeError(true);
          error = 2;
        }

        if (speciality === "") {
          setSpecialError(true);
          error = 2;
        }

        if (untildate === "") {
          setuntildateError(true);
          error = 2;
        }

        if (error > 1) {
          return false;
        }
      } else {
        setBtnDis(true);
        if (statusid === "doc_confirmed") {
          axiosInstance
            .get("/vozo/pat_mail_check?pid=" + patientid)
            .then((response) => {
              if (response.data === "pass") {
                // props.setspinnerfornewapp();
                setSpinnerVal(false);

                axiosInstance
                  .post("/vozo/appointment?pid=" + patientid, encryptedData)
                  .then((res) => {
                    let apptAdded = res.data;
                    // return;
                    // console.log(
                    //   res.data,
                    //   "response for video call doc confirmed appt"
                    // );
                    if (
                      MeetMode === "GMeet" &&
                      videochck &&
                      (statusid === "doc_confirmed" || statusid === "EMAIL")
                    ) {
                      axiosInstance
                        .get("/vozo/getGMeet/data")
                        .then((response) => {
                          var res = Decrypt_Value(response.data, "vozo");
                          let article = {
                            start_time: res["start_time"],
                            end_time: res["end_time"],
                            attendees: res["attendees"],
                            start: res["pc_startTime"],
                            end: res["pc_endTime"],
                            eventdate: res["pc_eventDate"],
                            eid: res["pc_eid"],
                            aid: res["pc_aid"],
                            pid: res["pc_pid"],
                          };
                          axios
                            .get(
                              GMeetURL +
                                "/interface/customized/g-meet/index.php?action=create",
                              { params: article }
                            )
                            .then((res) => {})
                            .catch((err) => {
                              console.log("failed");
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                    if (statusid === "doc_confirmed") {
                      axiosInstance
                        .get("/vozo/tele/checkemailsent")
                        .then((response) => {
                          props.savealertmsg(response.data, true);
                          setTimeout(() => props.popuphide(), 2000);
                        })
                        .catch((err) => console.error(err));
                    }

                    if (apptAdded === "out of both range") {
                      setBtnDis(false);
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected Date & time should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "out of time range") {
                      setBtnDis(false);
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected time should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "out of date range") {
                      setBtnDis(false);
                      setModalAlerShow1(true);
                      setModalMsg1(
                        "Selected Date should be within the provider availability range."
                      );
                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (apptAdded === "Provider Not Available") {
                      setBtnDis(false);
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1("Provider Not Available");
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else if (
                      apptAdded ===
                      "You Can't place the Appointment at this time"
                    ) {
                      setBtnDis(false);
                      setTimeout(() => {
                        setModalAlerShow1(true);
                        setModalMsg1(
                          "You Can't place the Appointment at this time"
                        );
                      }, 1);

                      setTimeout(() => setModalAlerShow1(false), 2000);
                      return;
                    } else {
                      props.savealertmsg(
                        "New Appointment Added Successfully",
                        true
                      );
                    }
                    setTimeout(() => props.popuphide(), 2000);
                    props.FetchAppointments(
                      props.provarray,
                      props.facarray,
                      props.appFromDate,
                      props.apptoDate,
                      props.pat_share,
                      props.examroom
                    );
                  })
                  .catch((err) => console.error(err));
              } else {
                alert("Please update a valid Email in Patient Demographics");
                props.closepopup();
              }
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              setBtnDis(false);
            });
        } else {
          // props.setspinnerfornewapp();

          await axiosInstance
            .post("/vozo/appointment?pid=" + patientid, encryptedData)
            .then((res) => {
              let apptAdded = res.data;
              // console.log(res.data, "response for  appt");
              // return;
              if (
                MeetMode === "GMeet" &&
                videochck &&
                (statusid === "doc_confirmed" || statusid === "EMAIL")
              ) {
                axiosInstance
                  .get("/vozo/getGMeet/data")
                  .then((response) => {
                    var res = Decrypt_Value(response.data, "vozo");
                    let article = {
                      start_time: res["start_time"],
                      end_time: res["end_time"],
                      attendees: res["attendees"],
                      start: res["pc_startTime"],
                      end: res["pc_endTime"],
                      eventdate: res["pc_eventDate"],
                      eid: res["pc_eid"],
                      aid: res["pc_aid"],
                      pid: res["pc_pid"],
                    };
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
              if (statusid === "doc_confirmed") {
                axiosInstance
                  .get("/vozo/tele/checkemailsent")
                  .then((response) => {
                    props.savealertmsg(response.data, true);
                    setTimeout(() => props.popuphide(), 2000);
                  })
                  .catch((err) => console.error(err));
              }

              if (apptAdded === "out of both range") {
                setBtnDis(false);
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date & time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of time range") {
                setBtnDis(false);
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected time should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "out of date range") {
                setBtnDis(false);
                setModalAlerShow1(true);
                setModalMsg1(
                  "Selected Date should be within the provider availability range."
                );
                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (apptAdded === "Provider Not Available") {
                setBtnDis(false);
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("Provider Not Available");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else if (
                apptAdded === "You Can't place the Appointment at this time"
              ) {
                setBtnDis(false);
                setTimeout(() => {
                  setModalAlerShow1(true);
                  setModalMsg1("You Can't place the Appointment at this time");
                }, 1);

                setTimeout(() => setModalAlerShow1(false), 2000);
                return;
              } else {
                props.savealertmsg("New Appointment Added Successfully", true);
              }
              setTimeout(() => props.popuphide(), 2000);
              props.FetchAppointments(
                props.provarray,
                props.facarray,
                props.appFromDate,
                props.apptoDate,
                props.pat_share,
                props.examroom
              );
              setBtnDis(false);
            })
            .catch((err) => {
              console.error(err);
              props.closepopup();
              setBtnDis(false);
            });
        }
      }
    }
  };

  const checkFacility = (type) => {
    if (type === "classname") {
      return sourceoffice && sourceoffice.length === 0 ? "hide-label" : "";
    } else if (type === "spinner") {
      return sourceoffice &&
        officeid &&
        sourceoffice.length === 0 &&
        officeid.length > 0 ? (
        <Spinner
          animation="border"
          size="sm"
          variant="primary"
          className="add-spinner-sty"
        />
      ) : (
        ""
      );
    }
  };

  const onChange = (timeStandard, typeTime) => {
    if (Appttype === 2 && timeStandard === "Invalid date") {
      return;
    }

    if (timeStandard === "Invalid date" && typeTime === "inTime") {
      setInTime(moment(start).format("h:mm A"));
      setOutTime(
        moment(moment(start, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(start, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );
      let inTimeNew = inTime.split(":");
      let inTimeMin = inTimeNew[1].split(" ");
      if (inTimeMin[1] === "PM") {
        if (
          moment(out, "h:mm A").isBetween(
            moment("12:00 AM", "h:mm A"),
            moment("1:00 AM", "h:mm A"),
            null,
            "[)"
          )
        ) {
          out = moment(out, "h:mm A").add(1, "day");
        }
      }
      let dur = moment(out, "hh:mm a").diff(
        moment(inTime, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else if (timeStandard === "Invalid date" && typeTime === "outTime") {
      setOutTime(
        moment(moment(inTime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );

      let out = moment(moment(inTime, "h:mm A").add(15, "minutes")).format(
        "h:mm A"
      );

      let dur = moment(outTime, "hh:mm a").diff(
        moment(out, "hh:mm a"),
        "minutes"
      );
      setEditing(dur);
    } else {
      let time = timeStandard.split(":");
      let timeMin = time[1].split(" ");

      if (typeTime === "inTime") {
        let intim = moment(timeStandard, "h:mm A").format("h:mm A");
        let out = moment(
          moment(timeStandard, "h:mm A").add(15, "minutes")
        ).format("h:mm A");
        let dur = moment(out, "hh:mm a").diff(
          moment(intim, "hh:mm a"),
          "minutes"
        );
        setEditing(dur);

        setInTimeErr(false);
        setOutTime(
          moment(moment(timeStandard, "h:mm A").add(15, "minutes")).format(
            "h:mm A"
          )
        );
        if (timeMin[1] === "AM") {
          if (time[0] === "12") {
            time[0] = Number(time[0]) - 12;
          }
          if (time[0] < Number(startTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        } else {
          if (time[0] !== "12") {
            time[0] = Number(time[0]) + 12;
          }
          if (time[0] >= Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          } else {
            settimelimiterrorbox(false);
          }
        }
        setapttime(timeStandard);
      } else {
        let inTimeNew = inTime.split(":");
        let inTimeMin = inTimeNew[1].split(" ");
        if (inTimeNew[0] === "12" && inTimeMin[1] === "AM") {
          inTimeNew[0] = "00";
        }

        let out = moment(timeStandard, "h:mm A").format("h:mm A");
        if (inTimeMin[1] === "PM") {
          if (
            moment(out, "h:mm A").isBetween(
              moment("12:00 AM", "h:mm A"),
              moment("1:00 AM", "h:mm A"),
              null,
              "[)"
            )
          ) {
            out = moment(out, "h:mm A").add(1, "day");
          }
        }
        let duration = moment(out, "hh:mm a").diff(
          moment(inTime, "hh:mm a"),
          "minutes"
        );
        setEditing(duration);

        if (inTime === timeStandard) {
          setInTimeErr(true);
        }
        if (inTime !== timeStandard) {
          setInTimeErr(false);
        }
        if (timeMin[1] === "AM") {
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (time[0] > Number(endTime) || inTimeNew[0] < Number(startTime)) {
            setInTimeErr(true);
            return false;
          } else if (
            (inTimeMin[1] === "PM" && time[0] !== "12") ||
            (Number(inTimeNew[0]) > Number(time[0]) &&
              Number(time[0]) !== 12) ||
            (inTimeNew[0] === time[0] &&
              inTimeMin[0] >= timeMin[0] &&
              time[0] !== "12") ||
            (time[0] === inTimeNew[0] &&
              timeMin[0] <= inTimeMin[0] &&
              time[0] !== "12")
          ) {
            setInTimeErr(true);
            return false;
          } else if (
            (Number(inTimeNew[0]) > time[0] && time[0] !== "12") ||
            (Number(inTimeNew[0]) === time[0] && inTimeMin[0] > timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          } else {
            setInTimeErr(false);
            settimelimiterrorbox(false);
          }
        } else {
          if (Number(time[0]) !== 12) {
            time[0] = Number(time[0]) + 12;
          }
          if (inTimeMin[1] === "PM" && Number(inTimeNew[0]) !== 12) {
            inTimeNew[0] = Number(inTimeNew[0]) + 12;
          }
          if (inTimeMin[1] === "AM" && Number(inTimeNew[0]) === 12) {
            inTimeNew[0] = 0;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) > 0) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) === Number(endTime) && Number(timeMin[0]) == 0) {
            settimelimiterrorbox(false);
          }
          if (Number(time[0]) > Number(endTime)) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(time[0]) < Number(endTime)) {
            settimelimiterrorbox(false);
          }
          if (Number(inTimeNew[0]) > Number(time[0])) {
            settimelimiterrorbox(true);
            return false;
          }
          if (Number(inTimeNew[0]) < Number(time[0])) {
            settimelimiterrorbox(false);
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) > Number(timeMin[0])
          ) {
            setInTimeErr(true);
            return false;
          }
          if (
            Number(inTimeNew[0]) === Number(time[0]) &&
            Number(inTimeMin[0]) < Number(timeMin[0])
          ) {
            setInTimeErr(false);
          }
          if (Number(inTimeNew[0]) < Number(startTime)) {
            settimelimiterrorbox(true);
          }
        }
      }
    }
  };

  const WeekdayChange = (val) => {
    setweekday(val);
    setselectdayError(false);
  };

  const hidehr = () => {
    document.getElementById("hide").className = "hide-hr";
  };

  const changeborder2 = () => {
    document.getElementById("border2").className = "border2";
  };

  const changeborder = () => {
    document.getElementById("border").className = "border0";
  };

  const handleDropDownSelect = (event, data) => {
    let options;
    if (videochck) {
      options = teleprofile;
    } else {
      options = sourceprofile;
    }
    for (var j = 0; j < options.length; j++) {
      if (options[j].value.includes(data.value)) {
        if (options[j].value == data.value) {
          if (videochck) {
            setbillingid("");
          } else {
            if (
              options[j].pc_bill_profile !== "DEFAULT" &&
              (options[j].pc_bill_profile ?? []).length > 0
            ) {
              setbillingid(options[j].pc_bill_profile);
            } else {
              setbillingid("");
            }
          }
          var filter_pc_duration = options[j].pc_duration;
          if (options[j].pc_duration !== "" || options[j].pc_duration !== "0") {
            let outTimeVal = moment(inTime, "h:mm A").add(
              options[j].pc_duration,
              "minutes"
            );
            setOutTime(moment(outTimeVal).format("h:mm A"));
          }
        }
      }
    }

    settimelimiterrorbox();
    setInTimeErr();
    setprofileid(data.value);
    setEditing(filter_pc_duration);
    setprofileError(false);
    setBillingError(false);
  };

  const handleDropDownBilling = (e, data) => {
    setbillingid(data.value);
    setBillingError(false);
  };

  const getRoom = async (id) => {
    await axiosInstance
      .get("/vozonew/facilityRoom?id=" + id)
      .then((res) => {
        let decode = Decrypt_Value(res.data, "vozo");
        setRoomList(decode.examRoom);
      })
      .catch((err) => {});
  };

  var i = 0;
  const setRoomList = (val) => {
    setSourceRoom([]);

    i = parseInt(val);
    for (let j = 1; j <= i; j++) {
      let obj = {
        id: "" + j,
        text: "Exam Room " + j,
        title: "Exam Room " + j,
        value: "" + j,
      };

      setSourceRoom((item) => [...item, obj]);
    }
    setRoomVal("");
  };

  const handleDropDownSelectOffice = (event, data) => {
    setproviderid("");
    setSpeciality("");
    setofficeid(data.value);
    setofficeError(false);
    getRoom(data.value);
  };

  const changeSpeciality = (data) => {
    setproviderid("");
    setSpeciality(data);
    setSpecialError(false);
    setInTimeErr(false);
  };

  const handleDropDownSelectStatus = (event, data) => {
    setstatusid(data.value);
    setstatusError(false);
  };

  const handleDropDownSelectProvider = (event, data) => {
    if (provError) {
      setprovError(false);
    }
    setproviderid(data.value);
    setprovError(false);
    setInTimeErr(false);
  };

  const handleBillingFacility = (event, data) => {
    setBillFacilityID(data.value);
    setBillFacilityError(false);
  };

  const handleDropDownSelectPatient = (event, data) => {
    setsearch1(data.title);
    setpatientid(data.value);
    setpatError(false);
    if (accountType > "0" || (accountType === "0" && trial)) {
      axiosInstance
        .get("/vozo/getpatient/flags?pid=" + data.value)
        .then((res) => {
          let temp_role_pat_flag = Decrypt_Value(res.data, "vozo");
          setPatFlag(temp_role_pat_flag);
        })
        .catch(() => {
          setPatFlag([]);
        });
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const walkinclick = () => {
    setwalkincheck(!walkincheck);
  };

  const onChangeRadio = (e) => {
    if (!storedNames.appt_a && telehealth[0]?.value != officeid) {
      setAppttype(1);
      setvideochck(false);
      alert("Provider's facility is not a telehealth facility");
    } else {
      setAppttype(e.target.value);
      if (e.target.value == 2) {
        //check selected times
        let t1 = inTime.split(" ");
        let t2 = outTime.split(" ");

        let from = disabledFromTimes(t1[0], t1[1], e.target.value);
        let to = disabledToTime(t2[0], t2[1], fromId, e.target.value);

        if (!from) {
          let intime = moment(start);
          let now = moment();

          if (intime.isAfter(now)) {
            if (props.view === "month") {
              let intime = moment(props.startTime1, "H").format("h:mm A");
              setInTime(intime);

              let outtime = moment(intime, "h:mm A")
                .add(15, "minutes")
                .format("h:mm A");
              setOutTime(outtime);

              let dur = moment(outtime, "h:mm A").diff(
                moment(intime, "h:mm A"),
                "minutes"
              );
              setEditing(dur);
              setapttime(intime);
            } else {
              let intime = now.format("h:mm A");
              setInTime(intime);

              let out = moment(intime, "h:mm A")
                .add(15, "minutes")
                .format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
              setapttime(intime);
            }
          }
        } else {
          let now = moment();

          let intime = now.format("h:mm A");

          let checktime = moment(aptdate, `${dtFormat} hh:mm a`);

          if (checktime.isBefore(now.format(dtFormat))) {
            setInTime("");
            setOutTime("");
            setEditing("");
          } else {
            let out = moment(
              moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
            ).format("h:mm A");

            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        // setofficeid(telehealth[0] ? telehealth[0].value : "");
        setprofileError(false);
        //  setEditing(teleprofile[0] ? teleprofile[0].pc_duration : "");
        setstatusid("");
        setbillingid("");
        setprofileid("");
      } else if (e.target.value == 1) {
        let now = moment();

        let intime = moment(start).format("h:mm A");
        setInTime(moment(start).format("h:mm A"));

        let checktime = moment(aptdate, `${dtFormat} hh:mm a`);
        if (checktime.isBefore(now.format(dtFormat))) {
          setInTime("");
          setOutTime("");
          setEditing("");
        } else {
          if (props.view === "month") {
            let inmonth = moment(props.startTime1, "H").format("h:mm A");
            setInTime(moment(props.startTime1, "H").format("h:mm A"));

            let out = moment(
              moment(props.startTime1, "H").add(15, "minutes")
            ).format("h:mm A");
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(inmonth, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(inmonth);
          } else {
            let out = moment(
              moment(intime, "h:mm A").add(15, "minutes")
            ).format("h:mm A");
            setInTime(intime);
            setOutTime(out);
            let dur = moment(out, "hh:mm a").diff(
              moment(now, "hh:mm a"),
              "minutes"
            );
            setEditing(dur);
            setapttime(intime);
          }
        }
        let ofc_firstId = "";
        if (props.officeId) {
          const idArray = props.officeId.split(",");
          ofc_firstId = idArray[0];
        }
        setofficeid(ofc_firstId);
        setprofileid("");
        setstatusid("");
        setbillingid("");
      }
    }
  };

  const checkvideovisit = () => {
    const currentTime = moment();
    if ((accountType === "1" && teleFlag === "0") || accountType === "-1") {
      setvideochck(false);
    } else {
      if (Appttype !== 3) {
        setproviderid("");
      }
      setvideochck(true);
      if (props.view === "month") {
        let intime = moment(props.startTime1, "H").format("h:mm A");
        setInTime(intime);

        let outtime = moment(intime, "h:mm A")
          .add(15, "minutes")
          .format("h:mm A");
        setOutTime(outtime);

        let dur = moment(outtime, "h:mm A").diff(
          moment(intime, "h:mm A"),
          "minutes"
        );
        setEditing(dur);
      } else {
        setInTime(currentTime.format("h:mm A"));
        setOutTime(moment(currentTime.add(15, "minutes")).format("h:mm A"));
      }
    }
  };

  const checkapt = () => {
    if (Appttype !== 3) {
      setproviderid("");
    }
    setvideochck(false);
  };

  const handleRoomVal = (data) => {
    setRoomVal(data);
    setexamroomError(false);
  };

  const handleSelect2 = (id, val) => {
    setOccurence("1");
    if (id === "new-app-select1") {
      setrepeatselectnew(val);
      seteverydayError(false);
    } else {
      setweekday("");
      if (val === "1") {
        setrepeatselect2new("1");
        if (
          repeatselectnew === "4" ||
          repeatselectnew === "5" ||
          repeatselectnew === "6"
        ) {
          setrepeatselectnew("");
        }

        setdayswise(true);
        setswict_check(true);

        setWeek_and_every(true);
        setswict_check_mnth(false);
      } else if (val === "2") {
        setrepeatselect2new("2");
        if (repeatselectnew === "5" || repeatselectnew === "6") {
          setrepeatselectnew("");
        }
        setswict_check_mnth(true);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else if (val === "3") {
        setrepeatselect2new("3");
        setswict_check_mnth(false);
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
      } else {
        setswict_check(false);

        setdayswise(false);
        setWeek_and_every(false);
        setswict_check_mnth(false);
      }
      setrepeatselect2new(val);
    }
  };

  const getDatas = async () => {
    let Data_frsh_doc =
      "authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&group_id=" +
      group_id;
    let Data_enc_doc = Encrypt_Value(Data_frsh_doc, "vozo");
    await axiosInstance
      .get("/vozonew/facility?search=" + Data_enc_doc)
      .then((response) => {
        let decryptval = Decrypt_Value(response.data, "vozo");
        setSourceoffice(decryptval);
      })
      .catch((err) => console.error(err));

    if (props.pid !== undefined) {
      setpatientid(props.pid);
    }
    if (!props.pid || props.pid === undefined) {
      let alle_data =
        "limit=40&authorized=" +
        authorized +
        "&main_pro=" +
        main_pro +
        "&userID=" +
        userID +
        "&pat_share=" +
        storedNames.pat_share +
        "&owner_id=" +
        owner_id +
        "&activepatlist=" +
        1;

      let alle_data_enc = Encrypt_Value(alle_data, "vozo");

      await axiosInstance
        .get("/vozo/patientsearch?search=" + alle_data_enc)
        .then((response) => {
          let temp_role = Decrypt_Value(response.data, "vozo");

          setSource(temp_role);
        })
        .catch(() => {
          setSource([]);
          setfornoresults("");
        });
    }
    if (props.pid !== undefined) {
      await axiosInstance
        .get("/vozonew/patient?pid=" + props.pid)
        .then((response) => {
          let temp_role_pat_flag = Decrypt_Value(response.data, "vozo");
          let alle_data =
            "limit=40&fname=" +
            temp_role_pat_flag.patient_name.toLowerCase() +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            userID +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id +
            "&activepatlist=" +
            1;

          let alle_data_enc = Encrypt_Value(alle_data, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + alle_data_enc)
            .then((response) => {
              let temp_role = Decrypt_Value(response.data, "vozo");
              setsearch1(temp_role[0].name);
              setSource(temp_role); // get 10 datas from response.data main array
              setfornoresults(temp_role);
            })
            .catch(() => {
              setsearch1("");
              setSource([]);
              setfornoresults("");
            });

          setpatientid(temp_role_pat_flag.pid);
        })
        .catch((err) => console.error(err));

      await axiosInstance
        .get("/vozo/getpatient/flags?pid=" + props.pid)
        .then((res) => {
          let temp_role_pat_flag = Decrypt_Value(res.data, "vozo");
          setPatFlag(temp_role_pat_flag);
        })
        .catch(() => {
          setPatFlag([]);
        });
    }
  };
  const handlecloseUpgrade = () => setUpgrade(false);

  const modalhide = () => {
    setModalAlerShow1(false);
    setModalMsg1("");
  };

  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //
  useEffect(() => {
    if (aptdate !== "") {
      const formattedApptDate = moment(aptdate, dtFormat);
      const newDate = formattedApptDate.clone().add(1, "days");
      setAftDate(newDate.format(dtFormat));
      setOnDate(newDate.format(dtFormat));
    }
  }, [aptdate]);

  useEffect(() => {
    axiosInstance
      .get("/vozo/telehealth/getPlatform")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "vozo");
        setTelehealthplatform(decodingResponse);
      })
      .catch((err) => {
        setTelehealthplatform("zoom");
      });
    axiosInstance
      .get("/vozo/appstatus/category")
      .then((response) => {
        let temp_role_cat = Decrypt_Value(response.data, "vozo");
        console.log("temp_role_category", temp_role_cat);
        setSourcestatus(temp_role_cat[0]);
        setSourcestatustele(temp_role_cat[1]);
        setSourceprofile(temp_role_cat[2]);
        setteleprofile(temp_role_cat[2]);
        setBillingProfile(temp_role_cat[4] ?? []);
        setSpinnerVal(false);
      })
      .catch((err) => console.error(err));
    axiosInstance
      .get("/vozonew/facility/telehealth")
      .then((response) => {
        let temp_role_tele_cat = Decrypt_Value(response.data, "vozo");
        settelehealth(temp_role_tele_cat);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    let ofc_firstId = "";
    if (props.officeId) {
      const idArray = props.officeId.split(",");
      ofc_firstId = idArray[0];
    }
    if (providerid !== "" && providerid !== null && providerid !== undefined) {
      axiosInstance.get("/sprovider?uid=" + providerid).then((response) => {
        const data = Decrypt_Value(response.data, "vozo");
        setAllowAppt(Number(data.appt_type));
        if (Number(data.appt_type) === 3 && Appttype !== 2) {
          setofficeid(ofc_firstId);
          setAppttype(1);
          setvideochck(false);
          setprofileid("");
          setstatusid("");
          setbillingid("");
          let dur = moment(outTime, "hh:mm a").diff(
            moment(inTime, "hh:mm a"),
            "minutes"
          );
          setEditing(dur);
        } else if (Number(data.appt_type) === 2) {
          setAppttype(2);
          let t1 = inTime.split(" ");
          let t2 = outTime.split(" ");

          let from = disabledFromTimes(t1[0], t1[1], 2);

          if (!from) {
            let intime = moment(start);
            let now = moment();

            if (intime.isAfter(now)) {
              setInTime(moment(start).format("h:mm A"));
              let intime = moment(start).format("h:mm A");
              let out = moment(
                moment(start, "h:mm A").add(15, "minutes")
              ).format("h:mm A");
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(intime, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
            }
          } else {
            let now = moment();

            let intime = now.format("h:mm A");

            let checktime = moment(aptdate, `${dtFormat} hh:mm a`);

            if (checktime.isBefore(now.format(dtFormat))) {
              setInTime("");
              setOutTime("");
              setEditing("");
            } else {
              let out = moment(
                moment(now.format("h:mm A"), "h:mm A").add(15, "minutes")
              ).format("h:mm A");

              setInTime(intime);
              setOutTime(out);
              let dur = moment(out, "hh:mm a").diff(
                moment(now, "hh:mm a"),
                "minutes"
              );
              setEditing(dur);
              setapttime(intime);
            }
          }

          setprofileError(false);
          // setEditing(teleprofile[0] ? teleprofile[0].pc_duration : "");
          setstatusid("");
          setbillingid("");
          setprofileid("");
          setvideochck(true);
        } else if (Number(data.appt_type) === 1) {
          setofficeid(ofc_firstId);
          setAppttype(1);
          setvideochck(false);
          setprofileid("");
          setstatusid("");
          setbillingid("");
        }
      });
    }
  }, [providerid, teleprofile]);

  useEffect(() => {
    const tempIntime = moment(start).format("h:mm A");
    if (props.view === "month") {
      let intime = moment(props.startTime1, "H").format("h:mm A");
      setInTime(intime);

      let outtime = moment(intime, "h:mm A")
        .add(15, "minutes")
        .format("h:mm A");
      setOutTime(outtime);

      let dur = moment(outtime, "h:mm A").diff(
        moment(intime, "h:mm A"),
        "minutes"
      );
      setEditing(dur);
    } else {
      setInTime(moment(start).format("h:mm A"));
      const tempIntime = moment(start).format("h:mm A");
      setOutTime(
        moment(moment(tempIntime, "h:mm A").add(15, "minutes")).format("h:mm A")
      );
    }

    getRoom(props.officeId);
    setaptdate(moment(start).format(dtFormat));
    setLastdateApp(moment(start).format(dtFormat));

    setapttime(moment(start).format("h:mm A"));
    setappfromdate(moment(start).format("YYYY-MM-DD"));
    setapptodate(moment(start).format("YYYY-MM-DD"));
    setproviderid(
      props.start === undefined
        ? props.provider
        : props.examroom !== true
        ? props.start.resourceId
        : props.provider
    );
    setRoomVal(
      props.start === undefined
        ? ""
        : props.examroom === true
        ? props.start.resourceId
        : ""
    );
    let ofc_firstId = "";
    if (props.officeId) {
      const idArray = props.officeId.split(",");
      ofc_firstId = idArray[0];
    }
    setofficeid(ofc_firstId);
    getDatas();
  }, [props.start]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      let data =
        "pat_share=" +
        storedNames.appt_a +
        "&main_pro=" +
        main_pro +
        "&owner_id=" +
        owner_id +
        "&userID=" +
        userID +
        "&authorized=" +
        authorized +
        "&facilityId=" +
        officeid +
        "&speciality=" +
        speciality +
        "&apttype=" +
        (Appttype == 1
          ? "Appointment"
          : Appttype == 2
          ? "Telehealth"
          : "Appointment");

      let data1 = Encrypt_Value(data, "vozo");

      let url;
      let user = authorized === "1" ? userID : main_pro;

      if (storedNames.appt_a) {
        url = "/vozonew/provider?search=" + data1;
      } else {
        url = "/vozonew/provider?uid=" + user;
      }

      axiosInstance
        .get(url)
        .then((response) => {
          let temp_role_prov = Decrypt_Value(response.data, "vozo");

          setSourceprovider(temp_role_prov);
          if (!storedNames.appt_a || authorized === "0") {
            setNotDisabledSpec(temp_role_prov[0].specialty);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [officeid, speciality, Appttype]);
  useEffect(() => {
    if (officeid !== "" && officeid !== undefined) {
      axiosInstance
        .get("vozo/speciality/getspeciality?officeid=" + officeid)
        .then((res) => {
          let decryptval = Decrypt_Value(res.data, "vozo");
          const countDict = decryptval.reduce((acc, item) => {
            acc[item.specialty] = item.count;
            return acc;
          }, {});

          const matchedSpecialties = specialityList.map((specialty) => ({
            specialty,
            count: countDict[specialty] || 0,
          }));
          matchedSpecialties.sort((a, b) => b.count - a.count);

          setSortSpecility(matchedSpecialties);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [officeid, speciality]);

  useEffect(() => {
    axiosInstance
      .get("vozo/getBillingFacility")
      .then((res) => {
        setBillFacility(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (patsrchvalue !== "") {
      let isCancelled = false;
      const handlechange = async () => {
        await timeout(1000);

        if (!isCancelled) {
          let alle_data =
            "limit=40&fname=" +
            patsrchvalue.toLowerCase() +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            userID +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id +
            "&activepatlist=" +
            1;

          let alle_data_enc = Encrypt_Value(alle_data, "vozo");

          axiosInstance
            .get("/vozo/patientsearch?search=" + alle_data_enc)
            .then((response) => {
              let temp_role = Decrypt_Value(response.data, "vozo");
              setFetchingOptions(false);
              setSource(temp_role); // get 10 datas from response.data main array
              setfornoresults(temp_role);
            })
            .catch(() => {
              setSource([]);
              setfornoresults("");
            });
        }
      };
      handlechange();
      return () => {
        isCancelled = true;
      };
    }
  }, [patsrchvalue]);

  useEffect(() => {
    setBillFacId(bill_facility_id);
    axiosInstance
      .get("vozo/calendarsettingdata/main")
      .then((response) => {
        let temp_role_cal = Decrypt_Value(response.data, "vozo");
        setworkDays(temp_role_cal.cal_drop);
        setStartTime(
          temp_role_cal.schedule_start === "0"
            ? "0"
            : temp_role_cal.schedule_start
        );
        setEndTime(
          temp_role_cal.schedule_end === "0" ? "24" : temp_role_cal.schedule_end
        );

        const timeSlots1 = [];
        const timeSlots2 = [];
        let i = 0;
        let j = 0;
        let skipFirstMidnight = true;
        if (
          temp_role_cal.schedule_start === "0" &&
          temp_role_cal.schedule_end === "24"
        ) {
          for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              let ampm = hour < 12 ? "AM" : "PM";
              let hourFormatted = hour === 0 ? 0 : hour > 12 ? hour - 12 : hour;
              if (hour === 24 && minute === 0) {
                ampm = "AM";
                hourFormatted = 12;
              }
              if (hour === 0) {
                hourFormatted = 12;
              }

              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
              if (time === "12:00" && ampm === "AM" && skipFirstMidnight) {
                skipFirstMidnight = false;
                continue;
              }
              if (time !== "00:00") {
                timeSlots2.push({ id: ++j, time: time, meridiem: ampm });
              }
              if (hour === 23 && minute === 45) {
                if (
                  timeSlots2[timeSlots2.length - 1].time === "11:45" &&
                  timeSlots2[timeSlots2.length - 1].meridiem === "PM"
                ) {
                  timeSlots2.push({ id: ++j, time: "12:00", meridiem: "AM" });
                }
              }
            }
          }
        } else {
          for (
            let hour = Number(temp_role_cal.schedule_start);
            hour < Number(temp_role_cal.schedule_end);
            hour++
          ) {
            for (let minute = 0; minute < 60; minute += 15) {
              const ampm = hour < 12 ? "AM" : "PM";
              const hourFormatted = hour > 12 ? hour - 12 : hour;
              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
              timeSlots1.push({ id: ++i, time: time, meridiem: ampm });
            }
          }
          const scheduleStart = Number(temp_role_cal.schedule_start);
          const scheduleEnd = Number(temp_role_cal.schedule_end);

          for (let hour = scheduleStart; hour <= scheduleEnd; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const isPM = hour >= 12;
              const ampm = isPM ? "PM" : "AM";
              let hourFormatted = hour % 12;
              if (hourFormatted === 0) hourFormatted = 12;
              const time = `${hourFormatted
                .toString()
                .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;

              timeSlots2.push({ id: ++j, time: time, meridiem: ampm });

              if (hour === scheduleEnd && minute === 0) break;
            }
          }
        }
        setFromTimeSlots(timeSlots1);
        var frSlotTmId = timeSlots1
          .filter((ele) => {
            return (
              moment(ele.time + ele.meridiem, "HH:mm A").format("HH:mm A") >=
              moment(start).format("HH:mm A")
            );
          })
          .map((ele) => {
            return ele.id;
          });

        setFromId(frSlotTmId.slice(0, 1));
        setToTimeSlots(timeSlots2);

        if (temp_role_cal.schedule_end >= 12) {
          if (temp_role_cal.schedule_end === "24") {
            setEndErrTime("12:00 AM");
          } else if (temp_role_cal.schedule_end !== "12")
            setEndErrTime(temp_role_cal.schedule_end - 12 + ":00 PM");
          else setEndErrTime("12:00 PM");
        } else {
          setEndErrTime(temp_role_cal.schedule_end + ":00 AM");
        }
        if (temp_role_cal.schedule_start >= 12) {
          if (temp_role_cal.schedule_start !== "12")
            setStartErrTime(temp_role_cal.schedule_start - 12 + ":00 PM");
          else setStartErrTime("12:00 PM");
        } else {
          setStartErrTime(temp_role_cal.schedule_start + ":00 AM");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [bill_facility_id, props.start]);

  useEffect(() => {
    const week_day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = new Date(moment(aptdate, dtFormat).format("YYYY-MM-DD"));
    setDay(week_day[day.getDay()]);

    setMonth(month[day.getMonth()]);

    setDyOfMont(day.getDate());

    const weekNum = Math.ceil(day.getDate() / 7);
    if (weekNum == 1) {
      setWkOfMont("First");
    } else if (weekNum == 2) {
      setWkOfMont("Second");
    } else if (weekNum == 3) {
      setWkOfMont("Third");
    } else if (weekNum == 4) {
      setWkOfMont("Fourth");
    } else {
      setWkOfMont("Fifth");
    }
  }, [aptdate, repeatselectmonth]);
  useEffect(() => {
    if (repeatselect2new === "0") {
      if (repeatselectnew === "1") {
        setOccurenceday(365);
      } else if (repeatselectnew === "2") {
        setOccurenceday(182);
      } else if (repeatselectnew === "3") {
        setOccurenceday(120);
      } else if (repeatselectnew === "4") {
        setOccurenceday(91);
      } else if (repeatselectnew === "5") {
        setOccurenceday(73);
      }
    } else if (repeatselect2new === "1") {
      if (repeatselectnew === "1") {
        setOccurenceday(52);
      } else if (repeatselectnew === "2") {
        setOccurenceday(26);
      } else if (repeatselectnew === "3") {
        setOccurenceday(13);
      }
    } else if (repeatselect2new === "2") {
      if (repeatselectnew === "1") {
        setOccurenceday(12);
      } else if (repeatselectnew === "2") {
        setOccurenceday(6);
      } else if (repeatselectnew === "3") {
        setOccurenceday(4);
      } else if (repeatselectnew === "4") {
        setOccurenceday(3);
      }
    }
    if (occurenceday < occurence) {
      setOccurencedayErr(true);
    } else {
      setOccurencedayErr(false);
    }
  }, [handleSelect2]);

  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [fromShow, setFromShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [fromId, setFromId] = useState("");
  const [toId, setToId] = useState("");

  const handleFromTime = () => {
    if (fromShow === false) {
      setFromShow(true);
      setToShow(false);
      setTempOpen(true);
    } else {
      setFromShow(false);
    }
  };
  const handleToTime = () => {
    if (toShow === false) {
      setToShow(true);
      setFromShow(false);
      setTempOpen(true);
    } else {
      setToShow(false);
    }
  };
  const handleFromButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };
  const handleToButton = (e, index) => {
    e.preventDefault();
    if (fromShow === true || toShow === true) {
      setFromShow(false);
      setToShow(false);
    }
  };

  const customRadio = (e) => {
    setcustchkerr(false);
    if (e.target.value === 1) {
      setdateselect2new("Never");
      setcustChk(1);
    } else if (e.target.value === 2) {
      setdateselect2new("On");
      setcustChk(2);
    } else if (e.target.value === 3) {
      setdateselect2new("After");
      setcustChk(3);
    }
  };

  window.onclick = function (e) {
    if (fromShow && tempOpen === false) {
      setFromShow(false);
    } else if (toShow && tempOpen === false) {
      setToShow(false);
    }
  };
  var regexFirstNum = /^[a-zA-Z].*/;

  useEffect(() => {
    if (aptdate !== "" && providerid !== "") {
      let encrypt = Encrypt_Value(
        { date: aptdate, uid: providerid, office: officeid },
        "vozo"
      );
      axiosInstance
        .post("/vozo/check/provider/availablity", encrypt)
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "vozo");

          let RepeatValues = decodingResponse?.filter(
            (pat) => pat.pc_recurrtype !== "0"
          );
          let NotRepeatValues = decodingResponse?.filter(
            (pat) => pat.pc_recurrtype === "0"
          );

          var repeatValues = repeatArray(RepeatValues, "FutureAppointment");

          let FinalResult = repeatValues[1].concat(NotRepeatValues);
          setUserAvail(FinalResult);
        })
        .catch((err) => {
          setUserAvail([]);
          console.error(err);
        });
    }
  }, [aptdate, providerid]);

  // ------------------------------ useEffect----end---------------------------------------------------- //

  return (
    <>
      <Modal.Title className="New-App_Mod-Title">New Appointment</Modal.Title>

      <Modal.Body className="c-pop New-App_Mod-Body" id="new-appts">
        {SpinnerVal ? (
          <SpinnerDiv height="432px">
            <Spinner animation="border" variant="primary" />
          </SpinnerDiv>
        ) : (
          <>
            <Div className="c-pop-one">
              <Form.Row className="app-block  antd-select-height1">
                {accountType > "0" || (accountType === "0" && trial) ? (
                  <>
                    {patFlag.map((item, ind) => (
                      <Div
                        key={ind}
                        className="new_appt_flag pt-fg-app-new"
                        style={{ backgroundColor: item.flag_color }}
                      >
                        <IoFlagOutline className="new_appt_flag_icon" />
                        <span className="new_appt_flag_amt">{`${item.flag_name} : ${item.description}`}</span>
                      </Div>
                    ))}
                  </>
                ) : (
                  ""
                )}

                <Div alignItems="center" className="search-pat p-r-20 wholediv">
                  <Div margin="-20px 0px 0px -2px">
                    <PeopleOutline className="people" />
                  </Div>
                  <Div className="dropdiv" id="PatId">
                    <Select
                      showSearch
                      placeholder="Search Patient by Name,SSN,Email,DOB"
                      id="new-app-patient_1 appt-inp-patsrch"
                      suffixIcon={<BsFillCaretDownFill />}
                      className={patError ? "err_mess_border w-100 " : "w-100"}
                      onSearch={(value) => {
                        setpatsrchvalue(value);
                        setFetchingOptions(true);
                      }}
                      onChange={(e, data) =>
                        handleDropDownSelectPatient(e, data)
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const searchValue = input
                          .toLowerCase()
                          .trim()
                          .replace(/[^\d]/g, "");
                        const searchValue1 = input
                          .toLowerCase()
                          .trim()
                          .replace(/[^a-z0-9@.]/g, "");
                        const { title, phone_cell, ss, email, dob } =
                          option.props; // Assuming these props are available

                        const dobFormatted = dob
                          ? moment(dob).format("MMDDYYYY")
                          : "";
                        const dobMMDD = dobFormatted.substring(0, 4);
                        const normalizedPhoneCell = phone_cell
                          ?.trim()
                          .replace(/[^\d]/g, "");
                        const normalizedss = ss
                          ?.toLowerCase()
                          .trim()
                          .replace(/[^\d]/g, "");
                        const normalizedtitle = title?.toLowerCase().trim();

                        const normalizedemail = email?.toLowerCase().trim();

                        if (searchValue.length === 0) return true;

                        if (searchValue.length <= 2) {
                          return (
                            normalizedPhoneCell?.startsWith(searchValue) ||
                            dobFormatted.startsWith(searchValue) ||
                            normalizedss?.toLowerCase().includes(searchValue) ||
                            normalizedemail
                              ?.toLowerCase()
                              .includes(searchValue1)
                          );
                        } else if (searchValue.length <= 4) {
                          return (
                            normalizedPhoneCell?.startsWith(searchValue) ||
                            dobMMDD === searchValue ||
                            dobFormatted.substring(0, 4) === searchValue ||
                            normalizedss?.toLowerCase().includes(searchValue) ||
                            normalizedemail
                              ?.toLowerCase()
                              .includes(searchValue1)
                          );
                        } else if (searchValue.length <= 8) {
                          return (
                            normalizedPhoneCell?.startsWith(searchValue) ||
                            dobFormatted === searchValue ||
                            normalizedss?.toLowerCase().includes(searchValue) ||
                            normalizedemail
                              ?.toLowerCase()
                              .includes(searchValue1)
                          );
                        }

                        return (
                          (normalizedtitle ?? "")
                            .toLowerCase()
                            .includes(searchValue) ||
                          normalizedPhoneCell.includes(searchValue) ||
                          (normalizedss ??
                            "".toLowerCase().includes(searchValue)) ||
                          (normalizedemail ??
                            "".includes(searchValue1.toLowerCase()))
                        );
                      }}
                      notFoundContent={
                        fetchingOptions ? "Loading..." : "No Data found"
                      }
                      value={
                        search1 && search1.length > 0 ? search1 : undefined
                      }
                      getPopupContainer={() => document.getElementById("PatId")}
                    >
                      {source.map((i) => {
                        const titleLength = i.text.length;

                        return (
                          <Option
                            key={i.value}
                            value={i.value}
                            title={i.text}
                            phone_cell={i.phone_cell}
                            dob={i.dob}
                            ss={i.ss}
                            email={i.email}
                          >
                            <div>
                              {titleLength < 15 ? (
                                <>
                                  {i.text} -{" "}
                                  {i.dob ? moment(i.dob).format(dtFormat) : " "}
                                </>
                              ) : (
                                <>
                                  <div>{i.text} -</div>
                                  <div>
                                    {i.dob
                                      ? moment(i.dob).format(dtFormat)
                                      : " "}
                                  </div>
                                </>
                              )}
                            </div>
                            <div>{i.phone_cell}</div>
                          </Option>
                        );
                      })}
                    </Select>

                    {patError && (
                      <p className="errormessage">Please select Patient</p>
                    )}
                  </Div>
                </Div>
                <Div style={{ display: "flex", margin: "-19px 0 4px 29px" }}>
                  <Div className="access_icon">
                    <AccessTimeIcon className="people" />
                  </Div>
                  <form
                    className={`${classes.container} frm-apoint`}
                    noValidate
                  >
                    <div
                      id="crt_appt-time-ok"
                      className="date-pick date-pick-parent app-datepick"
                    >
                      <DatePicker
                        onChange={(date) => {
                          if (date) {
                            setaptdate(moment(date).format(dtFormat));
                          } else {
                            setaptdate(moment().format(dtFormat));
                          }
                        }}
                        placeholder="From-"
                        format={dtFormat}
                        value={aptdate ? moment(aptdate, dtFormat) : null}
                        allowClear={false}
                      />

                      <div className="App-time">
                        <div className="showTime">
                          <input
                            className="fromTime-toTime"
                            onClick={() => {
                              handleFromTime();
                            }}
                            onMouseLeave={() => {
                              setTempOpen(false);
                            }}
                            value={inTime}
                            onChange={(e) => {
                              if (!regexFirstNum.test(e.target.value)) {
                                setFromShow(false);
                                setToShow(false);
                                setInTime(e.target.value);
                                setTimeout(() => {
                                  setInTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "inTime"
                                  );
                                }, 2000);
                              }
                            }}
                            onBlur={(e) => {
                              setInTime(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                )
                              );
                              onChange(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                ),
                                "inTime"
                              );
                            }}
                          />
                          <p className="time--space">-</p>
                          <input
                            className="fromTime-toTime"
                            onClick={() => {
                              handleToTime();
                            }}
                            onChange={(e) => {
                              if (!regexFirstNum.test(e.target.value)) {
                                setFromShow(false);
                                setToShow(false);
                                setOutTime(e.target.value);
                                setTimeout(() => {
                                  setOutTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "outTime"
                                  );
                                }, 2000);
                              }
                            }}
                            onMouseLeave={() => {
                              setTempOpen(false);
                            }}
                            onBlur={(e) => {
                              setOutTime(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                )
                              );
                              onChange(
                                moment(e.target.value, "h:mm A").format(
                                  "h:mm A"
                                ),
                                "outTime"
                              );
                            }}
                            value={outTime}
                          />
                        </div>

                        <div
                          className={
                            fromShow ? "fromArray-toArray" : "fromArray_hide"
                          }
                        >
                          {frtimeSlots.map((item, index) => {
                            return (
                              <button
                                key={item.id}
                                value={item.time + " " + item.meridiem}
                                disabled={disabledFromTimes(
                                  item.time,
                                  item.meridiem,
                                  Appttype
                                )}
                                onClick={async (e) => {
                                  setInTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  await setFromId(item.id);
                                  handleFromButton(e);
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "inTime"
                                  );
                                }}
                              >
                                {item.time + " " + item.meridiem}
                              </button>
                            );
                          })}
                        </div>
                        {/* To button */}
                        <div
                          className={
                            toShow ? "fromArray-toArray" : "toArray_hide"
                          }
                          style={{ left: 70 }}
                        >
                          {totimeSlots.map((item, index) => {
                            return (
                              <button
                                disabled={disabledToTime(
                                  item.time,
                                  item.meridiem,
                                  item.id,
                                  Appttype
                                )}
                                key={index}
                                value={item.time + " " + item.meridiem}
                                onClick={async (e) => {
                                  setOutTime(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    )
                                  );
                                  await setToId(item.id);
                                  handleToButton(e);
                                  onChange(
                                    moment(e.target.value, "h:mm A").format(
                                      "h:mm A"
                                    ),
                                    "outTime"
                                  );
                                }}
                              >
                                {item.time + " " + item.meridiem}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      {/* nisha end */}
                    </div>
                  </form>
                </Div>
                {inTimeErr && (
                  <Form.Text
                    id="new-app-time-codetext appt-warn-time"
                    className="colour-red p-l-66 m-t-0-imp m-b-3"
                  >
                    Please Select valid Time
                  </Form.Text>
                )}
                {timelimiterrorbox && (
                  <Form.Text
                    id="new-app-time-codetext appt-warn-time"
                    className="colour-red p-l-66 m-t-0-imp m-b-3"
                  >
                    Provider Only Available Between {startErrTime} to{" "}
                    {endErrTime}
                  </Form.Text>
                )}

                <Div alignItems="center" className="appointment_popup_div">
                  <Div id="new_appt_radio" className="appt-spn-vdovis">
                    <Radio.Group
                      onChange={
                        accountType === "-1" ||
                        (accountType === "1" && teleFlag === "0")
                          ? (e) => {
                              setUpgrade(true);
                            }
                          : onChangeRadio
                      }
                      value={forteleornor === 2 ? forteleornor : Appttype}
                    >
                      <Radio
                        className="radio_appt_btn"
                        value={1}
                        onChange={checkapt}
                        disabled={allowAppt === 2}
                      >
                        Appointment
                      </Radio>
                      <Radio
                        value={2}
                        onChange={checkvideovisit}
                        disabled={allowAppt === 1}
                      >
                        Video Visit
                      </Radio>
                      <Checkbox
                        className="app-dashboard-checkbox"
                        onClick={walkinclick}
                        disabled={videochck}
                        id="new-app-walkin"
                      >
                        Walk-in
                      </Checkbox>
                    </Radio.Group>
                  </Div>
                </Div>
                <Div
                  className="space-border1 w-100"
                  id="border1"
                  onClick={changeborder}
                  margin="15px 0 0 0"
                >
                  <Div alignItems="center" className=" alx p-r-20 wholediv">
                    <Div margin="-20px 0px 0px -2px">
                      <RoomOutlined />
                    </Div>
                    <Div className="dropdiv" id="officeId">
                      <Select
                        showSearch
                        placeholder="Select Office"
                        id="new-app-facility_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          officeError
                            ? `err_mess_border w-100 to-hide-ant-label ${checkFacility(
                                "classname"
                              )}`
                            : `to-hide-ant-label w-100 ${checkFacility(
                                "classname"
                              )}`
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectOffice(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={officeid ? officeid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("officeId")
                        }
                      >
                        {sourceoffice.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {checkFacility("spinner")}
                      {officeError && (
                        <p className="errormessage">Please select Office</p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div className="space-border1 w-100" margin="0">
                  <Div alignItems="center" className=" alx p-r-20 wholediv">
                    <Div margin="-20px 0px 0px -2px">
                      <Image
                        src={doctorLogo}
                        alt="speciality"
                        height="20px"
                        width="20px"
                      />
                    </Div>
                    <Div className="dropdiv">
                      <Select
                        showSearch
                        placeholder="Select Speciality"
                        suffixIcon={<BsFillCaretDownFill />}
                        className={
                          specialError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(data) => changeSpeciality(data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={speciality ? speciality : undefined}
                        optionLabelProp="label"
                        dropdownClassName="speciality-option"
                      >
                        {sortSpeciality &&
                          sortSpeciality.length !== 0 &&
                          sortSpeciality.map((item) => (
                            <Option
                              key={item.specialty}
                              value={item.specialty}
                              title={item.specialty}
                              disabled={
                                (!storedNames.appt_a &&
                                  item.specialty !== notDisabledSpec) ||
                                item.count === 0
                              }
                              id={
                                !storedNames.appt_a &&
                                item.specialty !== notDisabledSpec
                                  ? "clr-disable"
                                  : "" || item.count == 0
                                  ? "clr-disable"
                                  : ""
                              }
                              className={
                                (!storedNames.appt_a &&
                                  item.speciality !== notDisabledSpec) ||
                                item.count === 0
                              }
                            >
                              {item.speciality}
                            </Option>
                          ))}
                      </Select>
                      {specialError && (
                        <p className="errormessage">Select a speciality</p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div
                  alignItems="center"
                  className="search-pat p-r-20 appointment_patient_div"
                >
                  <Div margin="-2px 0px 0px -2px">
                    <PeopleOutline />
                  </Div>
                  <Div className="appointment_drop_div" id="provId">
                    <Select
                      showSearch
                      placeholder="Select Provider"
                      suffixIcon={<BsFillCaretDownFill />}
                      id="new-app-provider new-app-provider_1"
                      className={provError ? "err_mess_border w-100 " : "w-100"}
                      onChange={(e, data) =>
                        handleDropDownSelectProvider(e, data)
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.title ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={providerid ? providerid : undefined}
                      getPopupContainer={() =>
                        document.getElementById("provId")
                      }
                    >
                      {sourceprovider.map((i) => (
                        <Option key={i.value} value={i.value} title={i.text}>
                          {i.text}
                        </Option>
                      ))}
                    </Select>
                    {provError && (
                      <p className="errormessage">Please select Provider</p>
                    )}
                  </Div>
                </Div>
                <Div
                  className="app_repeat_dropdown"
                  margin=" 0px 0px 16px 68px"
                >
                  <BsFillCaretDownFill className="down_arrow_doesnt" />
                  <DropdownButton
                    id="arrow-newAppt"
                    className="app-nav-dropdown"
                    title={repvalue ? repvalue : "Does not repeat"}
                    onSelect={handlerepSelect}
                    variant="light"
                  >
                    <Dropdownant.Item
                      onClick={handleShowuntildate1}
                      eventKey="Does not repeat"
                    >
                      Does not repeat
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey="Daily"
                    >
                      Daily
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey={`Weekly on ${day}`}
                    >
                      Weekly on {day}
                    </Dropdownant.Item>
                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey={`Monthly on the ${wkOfMont} ${day}`}
                    >
                      Monthly on the {wkOfMont} {day}
                    </Dropdownant.Item>

                    <Dropdownant.Item
                      onClick={handleShowuntildate}
                      eventKey="Every Weekday(Monday to Friday)"
                    >
                      Every Weekday(Monday to Friday)
                    </Dropdownant.Item>

                    <Dropdownant.Item
                      eventKey="Custom"
                      onClick={handleShowrepApp}
                    >
                      Custom...
                      <BsFillCaretDownFill className="down_custom_txt" />
                    </Dropdownant.Item>
                  </DropdownButton>
                </Div>
                {lastdate && (
                  <Div margin=" 0px 0px 16px 68px" dis="flex">
                    <Span marginRight="3px" color="#000000 !important">
                      Until:
                    </Span>
                    <Div position="relative">
                      <DatePicker
                        disabledDate={(current) => {
                          const oneYearFromNow = moment()
                            .add(1, "year")
                            .startOf("day");
                          return (
                            current.isBefore(
                              moment(aptdate, dtFormat),
                              "day"
                            ) || current.isAfter(oneYearFromNow, "day")
                          );
                        }}
                        onChange={(date) => {
                          if (date) {
                            setLastdateApp(moment(date).format(dtFormat));
                          } else {
                            setLastdateApp(moment().format(dtFormat));
                          }
                        }}
                        placeholder="From-"
                        format={dtFormat}
                        value={
                          lastdateApp ? moment(lastdateApp, dtFormat) : null
                        }
                        allowClear={false}
                      />
                    </Div>
                  </Div>
                )}
                <hr id="hide" />
                <Div
                  className="space-border2 w-100"
                  id="border2"
                  onClick={hidehr}
                >
                  <Div className="wholediv ">
                    <Div margin="-20px 0px 0px -2px">
                      <Image
                        src={BillingFacilityIcon}
                        alt="bill"
                        height="20px"
                        width="20px"
                      />
                    </Div>

                    <Div className="dropdiv" id="billingFac">
                      <Select
                        showSearch
                        placeholder="Billing Facility"
                        suffixIcon={<BsFillCaretDownFill />}
                        id="new-app-status_1 appt-inp-stas"
                        className={
                          billFacilityError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) => handleBillingFacility(e, data)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={billFacilityID ? billFacilityID : undefined}
                        getPopupContainer={() =>
                          document.getElementById("billingFac")
                        }
                      >
                        {billFacility?.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {billFacilityError && (
                        <p className="errormessage">
                          Please Select the Facility
                        </p>
                      )}
                    </Div>
                  </Div>
                  <Div
                    className="wholediv"
                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <HomeOutlinedIcon />
                    </Div>
                    <Div className="dropdiv" id="ExaId">
                      <Select
                        showSearch
                        placeholder="Select Exam Room"
                        id="new-app-profile_1"
                        suffixIcon={<BsFillCaretDownFill />}
                        onClick={hidehr}
                        className={
                          examroomError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) => handleRoomVal(data.value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={roomval ? roomval : undefined}
                        getPopupContainer={() =>
                          document.getElementById("ExaId")
                        }
                      >
                        {sourceroom.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>
                      {examroomError && (
                        <p className="errormessage">Please select Exam Room</p>
                      )}
                    </Div>
                  </Div>
                </Div>
                <Div
                  className="space-border2 w-100"
                  id="border2"
                  onClick={hidehr}
                >
                  <Div
                    className="wholediv"
                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <PersonOutline />
                    </Div>
                    <Div className="dropdiv" id="ProfId">
                      <Select
                        className={
                          profileError ? "err_mess_border w-100" : "w-100"
                        }
                        showSearch
                        placeholder="Appointment Profile"
                        id="new-app-profile_1 appt-inp-profsrch"
                        suffixIcon={<BsFillCaretDownFill />}
                        onChange={(e, data) => handleDropDownSelect(e, data)}
                        onBlur={changeborder2}
                        onClick={hidehr}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={profileid ? profileid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("ProfId")
                        }
                      >
                        {sourceprofile.map((i) => (
                          <Option key={i.value} value={i.value} title={i.text}>
                            {i.text}
                          </Option>
                        ))}
                      </Select>

                      {profileError && (
                        <p className="errormessage">
                          Please select Appointment Profile
                        </p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div
                  className="space-border2 w-100"
                  id="border2"
                  onClick={hidehr}
                >
                  <Div
                    className="wholediv"
                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px" size="20px">
                      <IoWalletOutline />
                    </Div>
                    <Div className="dropdiv" id="BillId">
                      <Select
                        className={
                          billingError ? "err_mess_border w-100" : "w-100"
                        }
                        showSearch
                        placeholder="Billing Profile"
                        id="new-app-profile_1 appt-inp-profsrch"
                        suffixIcon={<BsFillCaretDownFill />}
                        disabled={videochck}
                        onChange={(e, data) => handleDropDownBilling(e, data)}
                        onBlur={changeborder2}
                        onClick={hidehr}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={billingid ? billingid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("BillId")
                        }
                      >
                        {videochck ? (
                          <>
                            {teleprofile.map((i) => (
                              <Option
                                key={i.value}
                                value={i.value}
                                title={i.text}
                              >
                                {i.text}
                              </Option>
                            ))}
                          </>
                        ) : (
                          <>
                            {billingprofile.map((i) => (
                              <Option
                                key={i.value}
                                value={i.value}
                                title={i.text}
                              >
                                {i.text}
                              </Option>
                            ))}
                          </>
                        )}
                      </Select>

                      {billingError && (
                        <p className="errormessage">
                          Please select Billing Profile
                        </p>
                      )}
                    </Div>
                  </Div>
                </Div>

                <Div className="space-border w-100" id="border">
                  <Div
                    className="wholediv"
                    // className="alx p-r-20"
                  >
                    <Div margin="-20px 0px 0px -2px">
                      <LocalHospitalOutlined />
                    </Div>
                    <Div
                      className={videochck ? "dropdiv downDropDown" : "dropdiv"}
                      id="statId"
                    >
                      <Select
                        showSearch
                        placeholder="Select Status"
                        id="new-app-status_1 appt-inp-stas"
                        suffixIcon={<BsFillCaretDownFill />}
                        onClick={changeborder}
                        // className="form-control "
                        className={
                          statusError ? "err_mess_border w-100" : "w-100"
                        }
                        onChange={(e, data) =>
                          handleDropDownSelectStatus(e, data)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.title ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={statusid ? statusid : undefined}
                        getPopupContainer={() =>
                          document.getElementById("statId")
                        }
                      >
                        {checking1 ? (
                          <></>
                        ) : (
                          <>
                            {videochck ? (
                              <>
                                {sourcestatustele.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            ) : (
                              <>
                                {sourcestatus.map((i) => (
                                  <Option
                                    key={i.value}
                                    value={i.value}
                                    title={i.text}
                                  >
                                    {i.text}
                                  </Option>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Select>
                      {statusError && (
                        <p className="errormessage">Please select Status</p>
                      )}
                    </Div>
                  </Div>
                </Div>
              </Form.Row>
            </Div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="client-app new_appt_btn_alt">
        <Button
          className="close_active close_active2 "
          variant="light"
          onClick={props.closepopup}
        >
          Cancel
        </Button>
        <Button
          id="appt-btn-sve"
          className="save_active save-btn"
          variant="primary"
          type="submit"
          onClick={buttonClickActions}
          disabled={btnDis}
        >
          Save
        </Button>
      </Modal.Footer>

      <Modal
        className="newModal app_custom"
        show={showrepApp}
        onHide={handleCloserepApp}
        backdrop="static"
      >
        <Modal.Title className="New-App_Mod-Title1">
          Custom recurrence
        </Modal.Title>
        <Modal.Body>
          <Div
            size="14px"
            margin="-13px 0px 16px 11px"
            className="repeat_every_app"
          >
            Every:
            <Select
              suffixIcon={<CustomSuffixIcon />}
              value={repeatselectnew ? repeatselectnew : ""}
              className="repeat-form-control repeat-antd"
              onChange={(e) => {
                handleSelect2("new-app-select1", e);
              }}
              // disabled={swict_drop}
            >
              <Option value="">Select</Option>
              <Option value="1">1</Option>
              {/* <Option value="1">every</Option> */}
              <Option value="2">2</Option>
              <Option value="3">3</Option>

              {(repeatselect2new === "0" || repeatselect2new === "2") && (
                <Option value="4">4</Option>
              )}

              {repeatselect2new === "0" && <Option value="5">5</Option>}
            </Select>
            <Select
              className="form-control form-select repeat-antd-day"
              value={repeatselect2new ? repeatselect2new : undefined}
              suffixIcon={<CustomSuffixIcon />}
              onChange={(e) => handleSelect2("new-app-select2", e)}
              // disabled={swict_drop}
            >
              <Option value="0">day</Option>
              {/* <Option value="4">workday</Option> */}
              <Option value="1">week </Option>
              <Option value="2">month</Option>
            </Select>
          </Div>
          {everydayError && (
            <p className="errormessage m-l-10 m-t--10 ">
              Please select repeat Option
            </p>
          )}
          <Div size="14px" margin="-10px 0px 23px 11px">
            {swict_check ? (
              <>
                <p>Repeats on:</p>
                <Div margin="15px 4px 14px 0px">
                  <ToggleButtonGroup
                    type="checkbox"
                    value={weekday}
                    className="appt_repeats but-act"
                    onChange={WeekdayChange}
                    style={
                      selectdayError
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                  >
                    <ToggleButton id="tbg-btn-1" value={1}>
                      S
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-2" value={2}>
                      M
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={3}>
                      T
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={4}>
                      W
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={5}>
                      T
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={6}>
                      F
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={7}>
                      S
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {selectdayError && (
                    <p className="errormessage">Please select atleast a day</p>
                  )}
                </Div>
              </>
            ) : (
              <></>
            )}
          </Div>

          <Div size="14px" margin="-10px 0px 23px 11px">
            {swict_check_mnth ? (
              <>
                <Div margin="15px 4px 37px 0px" width="172px">
                  <Select
                    suffixIcon={<CustomSuffixIcon />}
                    className="arrow-newApptadjust w-100"
                    onChange={(e) => setmonthDropdown(e)}
                    value={repeatselectmonth ? repeatselectmonth : "1"}
                    placeholder="Select"
                    defaultValue="1"
                    // disabled={swict_drop}
                  >
                    <Option value="1">Monthly on day {dyOfMont}</Option>
                    <Option value="2">
                      <Tooltip
                        title={`Monthly on the ${wkOfMont} ${day}`}
                        overlayClassName="insurance_tooltip1"
                      >
                        Monthly on the {wkOfMont} {day}
                      </Tooltip>
                    </Option>
                  </Select>
                </Div>
              </>
            ) : (
              <></>
            )}
          </Div>

          <Div size="14px" margin="-13px 0px 16px 11px">
            <p className="endpargin">Ends</p>
            <Radio.Group onChange={customRadio} value={custChk}>
              <Div
                className="rep-date1"
                dis="flex"
                justifyContent="space-between"
                padding="0px 42px 0px 0px"
              >
                <Radio className="radio_appt_btn1 sty-c-2e2e2e" value={2}>
                  On
                </Radio>

                <DatePicker
                  disabledDate={(current) => {
                    const oneYearFromNow = moment()
                      .add(1, "year")
                      .startOf("day");
                    return (
                      current.isBefore(moment(aptdate, dtFormat), "day") ||
                      current.isAfter(oneYearFromNow, "day")
                    );
                  }}
                  onChange={(e) => {
                    handlerepDate(e);

                    setdateselect2new("On");
                    setcustChk(2);
                  }}
                  format={dtFormat}
                  value={
                    onDate === ""
                      ? moment(aftDate, dtFormat)
                      : moment(onDate, dtFormat)
                  }
                  allowClear={false}
                />
              </Div>
              <Div margin="11px 0 0 0" className="rep-date2">
                <Radio className="radio_appt_btn2 sty-c-2e2e2e" value={3}>
                  After
                  <Div className="right" margin="0 0 0 52px">
                    <MdOutlineArrowDropUp className="up_arrow_custom" />
                    <MdOutlineArrowDropDown className="down_arrow_custom" />
                    <InputNumber
                      min={1}
                      max={occurenceday}
                      className="w-100 select_app_date1 occ_input1"
                      id="arrow-newApptadjust1"
                      value={occurence}
                      onChange={(val) => {
                        setcustChk(3);
                        setOccurence(val);
                        setdateselect2new("After");
                        if (val > occurenceday) {
                          setOccurencedayErr(true);
                        } else {
                          setOccurencedayErr(false);
                        }
                      }}
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event.target.value.length > 9
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <span className="occurence_p">Occurence</span>
                  </Div>
                  {occurencedayErr && (
                    <p className="errormessage m-l-92">
                      Please select occurence day
                    </p>
                  )}
                </Radio>
              </Div>
            </Radio.Group>
            {custchkerr && (
              <Form.Text
                id="new-app-time-codetext appt-warn-time"
                className="colour-red m-t-5 m-b-3"
              >
                Please Select valid End Time
              </Form.Text>
            )}
          </Div>
        </Modal.Body>
        <Modal.Footer className="client-app new_appt_btn_alt">
          <Button
            className="close_active close_active1 "
            variant="light"
            onClick={handleCloserepApp}
          >
            Cancel
          </Button>
          <Button
            className="save_active save-btn"
            variant="primary"
            type="submit"
            // onClick={buttonClickActions}
            onClick={handleSaverepApp}
            disabled={btnDis}
            margin="0 9px 0 0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="alert-popup-message eramsg"
        show={ModalAlerShow1}
        onHide={modalhide}
      >
        <div role="alert" className="alert-danger erapad">
          <BsExclamationOctagon className="icons-bs alert-icons-bts" />
          <span>{ModalMsg1}</span>
        </div>
      </Modal>

      {upgrade && (
        <UpgradeRequired
          title="Enable Remote Consultation"
          description="Consult patients through video visit remotely conserving resources and improving the care."
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
        />
      )}
    </>
  );
};
export default memo(NewAppointment);
